import styled from "styled-components"

// language=SCSS
const mixing = `
	&{
    border: none;
    appearance: none;
    pointer-events: none;
    background-color: transparent;
  }  
`

export const TableRowStyles = styled.tr`
	input { padding: 0 0.5em; }
  select { text-align: center; }

  input, select {
    width: 90%;
    font-size: 1rem;
    padding: 1px;
  }

  &.view-disabled * {
    color: #777777;
  }
  
  &.view, &.view-disabled {
    input, select {
      ${mixing}
    }
  }

  &.edit [readOnly] {
    ${mixing}
  }
`
import styled from "styled-components"
import {SessionTimeReport} from "../Hooks/useSessionCardData"
import SimpleCard from "./SimpleCard"

const Header = () => (
	<>
		<span style={{color: 'var(--gray1)'}}> Session Time </span>
		<span style={{color: 'var(--gray1)', fontSize: '0.75em'}}> (minutes) </span>
	</>
)

const Block = (props: { label: string, value: number, className?: string }) => (
	<div className={'block ' + props.className}>
		<span className='block-value'> {Math.round(props.value)} </span>
		<span className='block-label'> {props.label} </span>
	</div>
)

const MainContentBlock = styled(Block)`
  display: flex;
  align-items: flex-end;
  justify-content: center;

  span { display: inline-block; }

  .block-value {
    line-height: 1em;
    color: var(--card-content);
  }

  .block-label {
    color: var(--card-label);
    font-size: 0.75rem;
  }
`

const BottomGroupContainer = styled.div`
  position: absolute;
  bottom: 0;

  width: 100%;

  display: flex;
  justify-content: space-between;
  gap: 0.5rem;

  padding: 1rem 1rem 0.4rem;

  font-size: 1.1rem;

  span {
    display: inline-block;
  }
	
	// Makes the bottom block content display the value and label together
	//  will look better this way
	.block {
		line-height: 0.75rem;
		margin-bottom: 0.3rem;
	}

  .block-value {
    color: var(--card-content);
	  // Value and Label were too close
	  margin-right: 4px;
  }
	
  .block-label {
    font-size: 0.75em;
    color: var(--card-label);
  }
`

export default function SessionTimeCard(props: { content: SessionTimeReport }) {

	const {total, average, min, max} = props.content

	return (
		<SimpleCard
			label={<Header/>}
			content={<MainContentBlock label="Average" value={average}/>}
		>
			<BottomGroupContainer>
				<Block label="Min" value={min}/>
				<Block label="Max" value={max}/>
				<Block label="Total" value={total}/>
			</BottomGroupContainer>
		</SimpleCard>
	)
}
import create from "zustand"
import { WarningPopupProps } from "Popup/WarningPopup";

export interface WarningPopupContextProps {
	isOpen: boolean
	currentWarn: WarningPopupProps

	close: () => void
	open: (arg: WarningPopupProps) => void
}

const useWarningPopup = create<WarningPopupContextProps>(set => ({
	isOpen: false,
	currentWarn: {} as WarningPopupProps,

	open(arg) {
		set({isOpen: true, currentWarn: arg})
	},
	close() {
		set({isOpen:false, currentWarn: {} as WarningPopupProps})
	}
}))

export default useWarningPopup
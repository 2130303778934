import React from "react"
import { useFormikContext } from "formik"
import { ViewMode } from "Pages/UsersPage/UsersTableRow/index"
import { User, UserPermission } from "Types"

interface Props {
	viewMode?: ViewMode
	loggedUser: Pick<User, 'id' | 'permission'>
}

export function DrawPermissionDropdown({viewMode = 'view-disabled', loggedUser}: Props) {

	const {values, setFieldValue, handleBlur} = useFormikContext<User>()

	function onValueChange(e: React.ChangeEvent<HTMLSelectElement>) {
		return setFieldValue('permission', parseInt(e.target.value))
	}

	function shouldDisable(permissionValue: number) {
		// can not edit yourself
		if (loggedUser.id == values.id) return true;
		// can not edit someone with more permission than you
		if (loggedUser.permission < values.permission) return true;
		// can not grant more permission yours
		if (loggedUser.permission < permissionValue) return true;

		return false
	}

	return (
		<select
			value={values.permission} onChange={onValueChange}
			onBlur={handleBlur} tabIndex={viewMode === 'edit' ? 0 : -1}
		>
			{
				Object
					.values(UserPermission)
					.filter(item => typeof item == 'number' && item !== UserPermission.None)
					.map(key => (
						<option key={key} value={key} disabled={shouldDisable(key as number)}>
							{UserPermission[key as number]}
						</option>
					))
			}
		</select>
	)
}
import {loginRequest} from "authConfig";
import {msalInstance} from "index";

export default async function getToken(): Promise<string> {
	const account = msalInstance.getAllAccounts()[0];

	const accessTokenRequest = {
		scopes: loginRequest.scopes,
		account: account
	}

	try {
		return msalInstance.acquireTokenSilent(accessTokenRequest).then(res => res.accessToken)
	}
	// if somehow this token can not be acquired silently, try yo use a popup
	catch {
		return msalInstance.acquireTokenPopup(accessTokenRequest).then(res => res.accessToken)
	}
}
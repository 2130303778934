import {MsalProvider} from "@azure/msal-react";
import {msalInstance} from "index";

import AdminContextProvider from "Providers/AdminContextProvider";
import ViewModeContextProvider from "Providers/ViewModeContextProvider";

import React from "react";
import {Provider} from "react-redux";
import {store} from "Store/store";

export default function ProviderStack(props: { children: any }) {
	return (
		<MsalProvider instance={msalInstance}>
			<Provider store={store}>
				<AdminContextProvider>
					<ViewModeContextProvider>

						{props.children}

					</ViewModeContextProvider>
				</AdminContextProvider>
			</Provider>
		</MsalProvider>
	)
}
import { configureStore } from '@reduxjs/toolkit'
import {dashboardFiltersSlice} from "Store/dashboardFiltersSlice"
import {groupsSlice} from "Store/groupsSlice"
import {usersSlice} from "Store/usersSlice"
import { notificationSlice } from "./utility/notification.slice";
import { loadingProgressSlice } from "./utility/loadingProgress.slice";

import { installersSlice } from "Store/installersSlice";

export const store = configureStore({
	reducer: {
		groups: groupsSlice.reducer,
		users: usersSlice.reducer,
		installers: installersSlice.reducer,
		dashboardFilters: dashboardFiltersSlice.reducer,

		notification: notificationSlice.reducer,
		globalLoading: loadingProgressSlice.reducer
	}
})
import RangedDatePicker from "Components/RangedDatePicker"
import React from "react"
import {useAppDispatch, useAppSelector} from "Store/hooks"
import {dashboardFilterActions} from "Store/dashboardFiltersSlice"
import {selectHospitals, selectTrainings} from "Store/selectors"

export default function DashboardFilterControls() {

	const dispatch = useAppDispatch()

	const hospitalList = useAppSelector(selectHospitals)
	const trainingList = useAppSelector(selectTrainings)
	const dashboardFilters = useAppSelector(s => s.dashboardFilters)

	const setTraining = (value: string) => dispatch(dashboardFilterActions.setTrainingId(value))
	const setHospital = (value: string) => dispatch(dashboardFilterActions.setHospitalName(value))

	return (
		<div className="filter-controls">
			<label>
				<span> Period: </span>
				<RangedDatePicker
					startDate={dashboardFilters.period.begin}
					endDate={dashboardFilters.period.end}

					onChange={(begin, end) => {
						dispatch(dashboardFilterActions.setPeriod({
							begin: begin.toISODate(),
							end: end.toISODate()
						}))
					}}
				/>
			</label>

			<label>
				<span> Training: </span>
				<select value={dashboardFilters.trainingId} onChange={e => setTraining(e.target.value)}>
					<option value="">All</option>

					{
						trainingList
							.map(({id, name}) => (
								<option key={id} value={id}> {name} </option>
							))
					}

				</select>
			</label>

			<label>
				<span> Hospital: </span>
				<select value={dashboardFilters.hospitalName} onChange={e => setHospital(e.target.value)}>
					<option value="">All</option>

					{
						hospitalList
							.map(hName => (
								<option key={hName} value={hName}> {hName} </option>
							))
					}

				</select>
			</label>
		</div>
	)
}
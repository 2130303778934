import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface INotification {
	id: any
	message: string
}

const notificationAdapter = createEntityAdapter<INotification>({
	selectId: model => model.id
})

export const addNotification = createAsyncThunk('notification/create', (arg: INotification, thunkAPI): Promise<void> => {
	return new Promise(resolve => {
		setTimeout(() => resolve(), 3000)
	})
})

export const notificationSlice = createSlice({
	name: 'notification',
	initialState: notificationAdapter.getInitialState(),
	reducers: {
		removeNotification: (state, action: PayloadAction<INotification>) => {
			notificationAdapter.removeOne(state, action.payload.id)
		},

		removeNotificationById: (state, action: PayloadAction<string>) => {
			notificationAdapter.removeOne(state, action.payload)
		}
	},
	extraReducers: builder => builder
		.addCase(addNotification.pending, (state, action) => {
			notificationAdapter.addOne(state, action.meta.arg)
		})
		.addCase(addNotification.fulfilled, (state, action) => {
			notificationAdapter.removeOne(state, action.meta.arg.id)
		})
})

export const {removeNotification, removeNotificationById} = notificationSlice.actions;
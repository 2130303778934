import {BrowserRouter, Navigate, Route, Routes, useNavigate} from "react-router-dom";
import {InteractionType} from "@azure/msal-browser";
import {MsalAuthenticationTemplate, useMsal} from "@azure/msal-react";
import {loginRequest} from "authConfig";

import Initialization from "Misc/Initialization";
import useRoutesForLoggedUser from "Hooks/useRoutesForLoggedUser"
import CustomNavigationClient from "Services/CustomNavigationClient"

export default function AppRoutes() {
	return (
		<BrowserRouter>
			<RouterItems/>
		</BrowserRouter>
	)
}

function RouterItems() {

	const msal = useMsal()
	const navigate = useNavigate();
	const routesForUser = useRoutesForLoggedUser()

	msal.instance.setNavigationClient(new CustomNavigationClient(navigate))

	return (
		<MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
			<Routes>
				
				{
					routesForUser
						.map(({path, Page}) => (
							<Route key={path} path={path} element={<Page/>}/>
						))
				}

				
				{
					// 404 will redirect to the first available page
					routesForUser.length > 0 && (
						<Route path='*' element={ <Navigate to={routesForUser[0].path}/> }/>
					)
				}
			</Routes>

			<Initialization />
		</MsalAuthenticationTemplate>
	)
}
import useLoadingProgress from "Hooks/useLoadingProgress"
import React from "react"
import axios from "axios"
import styled from "styled-components"
import {IconDownload} from "Icons"

const DownloadButtonsStyles = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 1rem;
`
const DownloadButtonStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  //background-color: var(--blue3);
  border-radius: 5px;
  padding: 5px;
  //width: 120px;

  * {
    color: white;
    text-decoration: none;
  }

  svg *:not([fill="none"]) {
    fill: var(--orange0);
  }

  .download-button--container {
    display: inline-flex;
    flex-direction: row;
    gap: 0.5rem;

    align-items: center;
    justify-content: center;

    cursor: pointer;

    :hover {
      outline: 1px dotted var(--dark-blue);
      border-radius: 5px;
    }
  }
`

interface DownloadInstallerResponse
{
    version: string
    url: string
}

function WindowsDownloadButton()
{
    const progress = useLoadingProgress()

    function downloadInstaller(e: React.MouseEvent<HTMLAnchorElement>)
    {
        e.stopPropagation();
        progress.start()

        axios
            .get<DownloadInstallerResponse>('Installer/DownloadUrl')
            .then(res => window.open(res.data.url, "_blank"))
            .finally(() => progress.stop())
    }

    return (
        <DownloadButtonStyles>
            <a className="download-button" target="_blank" rel="noopener noreferrer" onClick={downloadInstaller}>
                <div className="download-button--container">
                    <IconDownload className='fill-gray0'/>
                    <p> Windows </p>
                </div>
            </a>
        </DownloadButtonStyles>
    )
}

function QuestDownloadButton()
{
    return (
        <DownloadButtonStyles>
            <a className="download-button" target="_blank"
               href="https://www.oculus.com/experiences/quest/5622267097875815/">
                <div className="download-button--container">
                    <IconDownload className='fill-gray0'/>
                    <p> Quest </p>
                </div>
            </a>
        </DownloadButtonStyles>
    )
}

export default function DownloadButtons()
{
    return (
        <DownloadButtonsStyles>
            <WindowsDownloadButton/>
            <QuestDownloadButton/>
        </DownloadButtonsStyles>
    )
}
import {Litepicker} from "litepicker"
import {DateTime} from "luxon"
import {createRef, useEffect} from "react"

interface PickerOnSelectedItem {
	lang: string
	dateInstance: Date
}

interface RangedDatePickerProps {
	startDate?: string
	endDate?: string

	onChange?: (begin: DateTime, end: DateTime) => void
}

export default function RangedDatePicker(props: RangedDatePickerProps) {

	const inputRef = createRef<HTMLInputElement>()

	useEffect(() => {
		const datePicker = inputRef.current

		if (!datePicker) return;

		// The LitePicker does not work properly without the "time" part of the ISO dateTime format
		//  since we are only using Date (not DateTime) on this portal, we need to wrap and fix those values
		//  before sending to LitePicker.
		const {endDate, startDate} = getNormalizedStartEndDate(props.startDate, props.endDate)
		
		const picker = new Litepicker({
			element: datePicker,
			splitView: true,
			singleMode: false,

			format: {
				// parse function should return Date object
				// date - Date object or string (perhaps there will be more types, need to check)
				parse(date: any) : Date {
					if (date instanceof Date) {
						return date;
					}

					if (typeof date === 'string') {
						return DateTime.fromISO(date).toJSDate()
					}

					// from unix timestamp (eg.: new Date().getTime())
					if (typeof date === 'number') {
						return new Date(date);
					}

					return new Date();
				},

				// date - Date object
				// output function should return string
				output(date: Date): string {
					return DateTime.fromJSDate(date).toLocaleString()
				}
			},
			startDate: startDate,
			endDate: endDate,

			setup: p => {
				p.on('selected', (a: PickerOnSelectedItem, b: PickerOnSelectedItem) => {
					if (!props.onChange) return

					const begin = DateTime.fromJSDate(a.dateInstance)
					const end = DateTime.fromJSDate(b.dateInstance)
					props.onChange(begin, end)
				})
			}
		})

		return () => picker.destroy()
	}, [inputRef, props])

	return (
		<input type="text" ref={inputRef}/>
	)
}

function getNormalizedStartEndDate(startDate?: string, endDate?: string) {
	const fix = (isoDate: string | undefined) => {
		const dateTime = DateTime.fromISO(isoDate ?? '')
		return dateTime.isValid ? dateTime : undefined
	}

	return {
		startDate: (fix(startDate) ?? DateTime.now()).toISO(),
		endDate: (fix(endDate) ?? DateTime.now().plus({day: 1})).toISO()
	}
}
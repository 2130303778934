import axios from "axios"
import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { Installer } from "Types";

export const installerAdapter = createEntityAdapter<Installer>({
	selectId: model => model.version,
	sortComparer: (a, b) => {
		const dateA = DateTime.fromISO(a.uploadDate)
		const dateB = DateTime.fromISO(b.uploadDate)

		return dateA < dateB ? 1 : -1
	}
})

export const installersActions = {
	list: createAsyncThunk<Installer[]>('installers/list', async (arg) => {
		return axios.get<Installer[]>('Installer?includeInactive=true').then(res => res.data)
	}),

	delete: createAsyncThunk<void, string>('installer/delete', async (arg) => {
		await axios.delete(`Installer/${ arg }`)
	}),

	update: createAsyncThunk<void, Pick<Installer, "isActive" | "version">>('installer/setIsActive', async (arg) => {
		const {isActive, version} = arg
		await axios.put(`Installer/${ version }/Activate/${ isActive }`)
	})
}

export const installersSlice = createSlice({
	name: 'instalers',
	initialState: installerAdapter.getInitialState(),
	reducers: {},
	extraReducers: builder => builder
		.addCase(installersActions.list.fulfilled, (state, action) => {
			installerAdapter.removeAll(state)
			installerAdapter.addMany(state, action.payload)
		})
		.addCase(installersActions.delete.fulfilled, (state, action) => {
			installerAdapter.removeOne(state, action.meta.arg)
		})
		.addCase(installersActions.update.fulfilled, (state, action) => {
			const {isActive, version} = action.meta.arg;

			 installerAdapter.updateOne(state, {
				 id: version,
				 changes: { isActive }
			 })
		})
})
import {useFormikContext} from "formik"
import {ViewMode} from "Pages/UsersPage/UsersTableRow/index"
import React, {ChangeEvent} from "react"
import {User} from "Types"

export function DrawUserStatus(props: { viewMode: ViewMode }) {

	const {values, handleBlur, setFieldValue} = useFormikContext<User>()

	function onChange(e: ChangeEvent<HTMLSelectElement>) {
		return setFieldValue('isActive', e.target.value === "1");
	}

	const tabIndex = props.viewMode == 'edit' ? 0 : -1;
	return (
		<select
			name="isActive"
			value={values.isActive ? "1" : "0"} onChange={onChange}
			onBlur={handleBlur} tabIndex={tabIndex}
		>
			<option value={"1"}>Active</option>
			<option value={"0"}>Disabled</option>
		</select>
	)
}
import useIsAdmin from "Hooks/useIsAdmin.hook";
import { createContext, useContext, useEffect, useState } from "react";

export enum ViewMode {
	User = "User",
	Admin = "Admin"
}

interface ViewModeProps {
	canChange: boolean

	mode: ViewMode
	setViewMode: (newMode: ViewMode) => void
}

export const ViewModeContext = createContext({} as ViewModeProps)
export const useViewMode = () => useContext(ViewModeContext);

export default function ViewModeContextProvider (props: {children: any}) {

	const isAdmin = useIsAdmin();
	const [viewMode, baseSetViewMode] = useState<ViewMode>(ViewMode.User);

	useEffect(() => {
		if (isAdmin === null)
			return

		// Is the user is not admin, it will be always a "User"
		if (!isAdmin) {
			baseSetViewMode(ViewMode.User)
		}
		// If the user is an admin check for the last state on localStorage
		else {
			const lastSessionViewMode = localStorage.getItem("pref:view-mode") as ViewMode ?? ViewMode.Admin
			baseSetViewMode(lastSessionViewMode)
		}
	}, [isAdmin])

	function setViewMode(newMode: ViewMode) {
		if (!isAdmin)
			return

		baseSetViewMode(newMode)
		localStorage.setItem("pref:view-mode", newMode.toString())
	}

	return (
		<ViewModeContext.Provider value={{
			mode: viewMode,
			setViewMode: setViewMode,
			canChange: isAdmin ?? false
		}}>
			{props.children}
		</ViewModeContext.Provider>
	)
}
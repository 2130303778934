import {usePermission} from "Hooks/usePermission"
import TrainingDurationChartPanel from "Pages/DashboardPage/Panels/TrainingDurationChartPanel"
import DefaultTemplate from "Templates/Default";
import {UserPermission} from "Types"
import SessionTimeCard from "./Cards/SessionTimeCard"

import SimpleCard from "./Cards/SimpleCard"
import DashboardFilterControls from "./DashboardFilterControls"

import useSessionCardData from "./Hooks/useSessionCardData"

import DownloadsChartPanel from "./Panels/DownloadsChartPanel"
import TrainingsPerDayChartPanel from "./Panels/TrainingsPerDayChartPanel"

import {Styles} from "./styles"

export default function DashboardPage() {
	return (
		<DefaultTemplate title="Dashboard" verticalExpand>
			<Styles>

				<DashboardFilterControls/>
				<DrawCards/>
				<DrawCharts/>

			</Styles>
		</DefaultTemplate>
	)
}

function DrawCards() {
	const sessionCardsData = useSessionCardData()

	return (
		<div className="card-list">
			<SimpleCard label={"Sessions"} content={sessionCardsData.sessionCount}/>
			<SimpleCard label={"Instructors"} content={sessionCardsData.instructorsCount}/>
			<SimpleCard label={"Students"} content={sessionCardsData.studentsCount}/>
			<SimpleCard label={"Hospitals"} content={sessionCardsData.hospitalCount}/>
			<SimpleCard label={"Downloads"} content={sessionCardsData.downloadsCount}/>
			<SessionTimeCard content={sessionCardsData.sessionTime}/>
		</div>
	)
}

function DrawCharts() {

	const canSeeDownloads = usePermission(UserPermission.Admin)

	return (
		<div className="graph-list">
			<TrainingDurationChartPanel/>
			<TrainingsPerDayChartPanel/>
			
			{
				canSeeDownloads && <DownloadsChartPanel/>
			}
		</div>
	)
}
import styled from "styled-components";

export const UsersPageStyles = styled.div`
  display: grid;
  grid-template-rows: min-content min-content auto;

  padding: 2rem;
  min-height: 92%;

  font-size: 1.2rem;
  background-color: hsl(0, 0%, 98%);

  table {
    margin-top: 1rem;
    table-layout: fixed;

	  // Make icon grayed for buttons you can't interact with
    button:disabled * { fill: var(--gray2); }

    tbody td {
	    // Make the table use all available horizontal space
      width: calc(100% / 4);
      height: 2.1rem;
	    
      :last-child {
	      // This size is good enough to encapsulate the buttons of "edition mode"
        width: 120px;
	      
	      // Align horizontally to right
        display: inline-flex;
        justify-content: flex-end;
      }
    }

    .action-buttons-container--edit-mode {
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: flex-end;
    }
  }
`
import React, {useEffect, useState} from "react";
import axios from "axios"
import {useMsal} from "@azure/msal-react";
import {useAppSelector} from "Store/hooks"
import {Container} from "./UserProfileImageAndLoginInfo.styles"
import create from 'zustand'

interface ProfilePic {
	url?: string
	isDownloading: boolean
	download: () => void
}

const useProfilePicture = create<ProfilePic>((set, get) => ({
	url: '',
	isDownloading: false,

	download: () => {
		if (get().isDownloading) return

		set({isDownloading: true})

		downloadProfilePicture(5)
			.then(url => set({url}))
			.finally(() => set({isDownloading: false}))
	}
}))

export default function UserProfileImageAndLoginInfo() {
	const msal = useMsal();
	
	const profilePic = useProfilePicture()
	const me = useAppSelector(s => s.users.me)
	
	useEffect(() => {
		if(!me || profilePic.url || profilePic.isDownloading) 
			return
		
		profilePic.download()
		
	}, [me, profilePic.url])

	return (
		<Container tabIndex={0}>
			{
				profilePic.url 
					? <img src={ profilePic.url } alt="profile"/>
					: <div className="white-circle"/>
			}

			<div className="extra-user-info t-center">
				<br/>
				<div>
					<h2> { me?.displayName } </h2>
					<p>  { me?.email } </p>
				</div>
				<br/>
				<br/>

				<a className="c-dark2" style={ {cursor: 'pointer'} } onClick={ () => msal.instance.logoutRedirect() }>
					Sing Out
				</a>
			</div>
		</Container>
	)
}

async function downloadProfilePicture(maxTry: number, signal?: AbortSignal) {
	// Sometimes the server returns a CORS error
	//  this is not related to CORS, but something that I don't know yet...
	//
	// Adding a few retries make it work
	
	for (let tryCount = 0; tryCount < maxTry; tryCount++) {
		try {
			const result = await axios.get('users/me/thumbnail', {responseType: "blob", signal})
			return URL.createObjectURL(result.data)
		} catch (e) {
			if (tryCount + 1 == maxTry) {
				console.error(e)
			}
		}
	}
}
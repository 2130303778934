import { ReactComponent as IconDelete } from "Assets/icons/X_ICON.svg";
import { ReactComponent as IconEdit } from "Assets/icons/Edit_ICON.svg";
import { ReactComponent as IconUsers } from 'Assets/icons/Users_ICON.svg';
import { ReactComponent as IconCamera } from "Assets/icons/Photo_ICON.svg";
import { ReactComponent as IconSearch } from "Assets/icons/Search_ICON.svg";
import { ReactComponent as IconLicense } from 'Assets/icons/License_ICON.svg';
import { ReactComponent as IconDashboard } from 'Assets/icons/Dashboard_ICON.svg';
import { ReactComponent as IconEditPlans } from 'Assets/icons/EditPlans_ICON.svg';
import { ReactComponent as IconEditParameters } from 'Assets/icons/EditParameters_ICON.svg';
import { ReactComponent as IconInstaller } from 'Assets/icons/Installer_ICON.svg';
import { ReactComponent as IconCheck } from 'Assets/icons/Check_ICON.svg';
import { ReactComponent as IconWarning } from 'Assets/icons/Warning_ICON.svg';
import { ReactComponent as IconTrashBin } from 'Assets/icons/TrashBin.svg';
import { ReactComponent as IconDownload } from 'Assets/icons/cloud_download.svg';
import { ReactComponent as IconSort } from "Assets/icons/Sort.svg"
import { ReactComponent as IconFilter } from "Assets/icons/Filter.svg"

import styled from "styled-components";

const IconPlus = styled(IconDelete)`
	transform: rotateZ(45deg);
`
const IconInfinite = () => <span> &infin; </span>

export {
	IconEdit,
	IconPlus,
	IconCheck,
	IconUsers,
	IconDelete,
	IconCamera,
	IconSearch,
	IconLicense,
	IconWarning,
	IconInfinite,
	IconDownload,
	IconTrashBin,
	IconDashboard,
	IconEditPlans,
	IconInstaller,
	IconEditParameters,
	IconSort, 
	IconFilter
}
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const loadingProgressSlice = createSlice({
	name: 'Loading progress',
	initialState: {
		isLoading: false,
		waitingCounter: 0
	},
	reducers: {
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.waitingCounter += action.payload ? 1 : -1
			state.isLoading = state.waitingCounter > 0
		}
	}
})

export const {...globalLoadingActions} = loadingProgressSlice.actions
import React from 'react';
import styled from "styled-components";

const PanelStyles = styled.div`
  display: flex;
  flex-direction: column;

	border-radius: 4px;
	background-color: var(--panel-background);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
	
	.panel-label   { text-align: center; }
  .panel-content { flex-grow: 1; }
`

export interface IPanelProps extends React.HTMLAttributes<HTMLDivElement> {
	title: string
	expandContent?: boolean
}

export default function Panel({title, children, ...rest}: IPanelProps) {
	return (
		<PanelStyles {...rest} className="panel-root">
			<h2 className="panel-label">{title}</h2>

			<div className="panel-content">
				{children}
			</div>
		</PanelStyles>
	);
};
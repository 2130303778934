import React, {ChangeEvent, HTMLAttributes} from "react"
import styled from "styled-components"
import {IconFilter} from "Icons"

const Container = styled.button`
  position: relative;

  width: 25px;
  height: 25px;

  border: none;
  background: none;
  //outline: 1px solid grey;
  border-radius: 5px;
  margin: 0 5px;

  select {
    position: absolute;
    opacity: 0;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }

  .filter-icon-container {
    display: grid;
    place-items: center;

    svg {
      transform: scale(0.7);

      *:not([fill="none"]) {
        fill: rgba(0, 0, 0, 0.2);
      }
    }
  }
`

interface TheProps<TValue> extends Omit<HTMLAttributes<HTMLButtonElement>, 'onClick'> {
	activeOption: string
	options: { key: string, value: TValue }[]
	onOptionSelected(key: string, value: TValue): void
}

export function ButtonFilterPopup<TValue>({options, onOptionSelected, activeOption, ...props}: TheProps<TValue>) {

	function handleOptionSelected(e: ChangeEvent<HTMLSelectElement>) {
		const key = e.currentTarget.value
		const value = options.filter(o => o.key == key)[0].value

		onOptionSelected(key, value)
	}

	return (
		// Block click event propagation
		// this is important to prevent the triggering of table sorting
		<Container {...props} onClick={(e) => e.stopPropagation()}>
			<span className="filter-icon-container"> 
				<IconFilter className={'fill-black'}/> 
			</span>
			
			<select onChange={handleOptionSelected} value={activeOption}>
				{
					options
						.map((item) => (
							<option key={item.key} value={item.key}> 
								{item.value} 
							</option>
						))
				}
			</select>
		</Container>
	)
}
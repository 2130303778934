import BetterTable from "Components/BetterTable"
import styled from "styled-components"

export const BetterTableStyled = styled(BetterTable)`

  width: 100%;
  margin-top: 1rem;

  tr { line-height: 1.75em; }

  td {
    color: var(--table-content);
  }

  tbody {
    td { font-size: 1rem; }

    td, th {
      text-align: center;
	    
	    // Force text to keep expanding the line
	    // instead of breaking it into a new one
      white-space: nowrap;
    }

		// Vertically center the download button
    td:last-child svg { transform: translateY(4px); }
  }

  // ******************************
  //	Filter-popup related stuff
  // ******************************
  .filter-popup {
    opacity: 0;

    * { cursor: pointer; }
	  
	  transition: opacity 50ms;
  }

  // Anchor filter at center right
  thead td {
    position: relative;

    // Force column head to have an extra space to not be overlapped by filter-popup
    padding: 0 2rem;

    .filter-popup {
      position: absolute;

      right: 0;
      top: 50%;

      transform: translateY(-50%);
    }
  }

  // display filter if user hovers it
  thead td:hover .filter-popup{
    opacity: 1;
  }

  // keep filter visible while it's being in use
  .filter-popup[data-has-filter = "true"] {
    opacity: 1;

    *:not([fill="none"]) { fill: var(--orange0) }
  }
`
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { createContext, useEffect, useState } from "react";

interface AdminContextProps {
	isAdmin: boolean | null
}

interface GetUserResult {
	id: string
	email: string
	displayName: string
	isAdmin: boolean
}

export const AdminContext = createContext({} as AdminContextProps)

export default function AdminContextProvider (props: {children: any}) {

	const msal = useMsal();

	const [isFetchingAdmin, setFetchingAdmin] = useState(false)
	const [isAdmin, setIsAdmin] = useState<boolean | null>(null)

	// Get user information to check if is an admin or not
	useEffect(() => {
		if (isAdmin !== null || isFetchingAdmin || msal.accounts.length == 0)
			return

		setFetchingAdmin(true)

		axios.get<GetUserResult>('users/Me')
			.then(res => setIsAdmin(res.data.isAdmin))
			.finally(() => setFetchingAdmin(false))
	}, [isAdmin, isFetchingAdmin, msal])

	return (
		<AdminContext.Provider value={{isAdmin}}>
			{props.children}
		</AdminContext.Provider>
	)
}
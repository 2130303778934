import {createAsyncThunk, createEntityAdapter, createSlice, PayloadAction} from "@reduxjs/toolkit"
import axios from "axios"
import {generateFakeSession} from "Store/fakeDataGeneration"
import {DownloadReportItem, Group, Session, Training} from "Types"

interface FetchReportsArgs {
	groupId: string
	fromDate?: string
	toDate?: string
}

interface FetchDownloadsReportsArgs {
	fromDate?: string
	toDate?: string
}


export const groupActionsAsync = {
	list: createAsyncThunk<Group[]>('groups/list', async (arg) => {
		return axios.get<Group[]>('groups').then(res => res.data)
	}),

	listHospitals: createAsyncThunk<string[], { groupId: string }>('groups/list-hospitals', async (arg) => {
		return axios.get(`groups/${arg.groupId}/hospitals`).then(res => res.data)
	}),

	fetchReports: createAsyncThunk<Session[], FetchReportsArgs>('groups/fetch-reports', async (arg) => {
		const {groupId, ...queryItems} = arg

		const queryParams = Object
			.entries(queryItems)
			.reduce((result, [key, value], index) => {
				if (!value) return result

				const prefixSymbol = index == 0 ? '?' : '&'
				return result + `${prefixSymbol}${key}=${value}`
			}, '')

		return axios.get(`reports/${groupId}${queryParams}`).then(res => res.data)
	}),

	fetchDownloadReports: createAsyncThunk<DownloadReportItem[], FetchDownloadsReportsArgs>('groups/fetch-software-downloads', async (arg, thunkAPI) => {
		const queryParams = Object
			.entries(arg)
			.reduce((result, [key, value], index) => {
				const prefixSymbol = index == 0 ? '?' : '&'
				return result + `${prefixSymbol}${key}=${value}`
			}, '')

		return axios.get(`installer/reports/downloads${queryParams}`).then(res => res.data)
	}),
	
	fetchTrainingNames: createAsyncThunk<Training[], void>('groups/fetch-training-names', async (arg, thunkAPI) => {
		return axios.get<Training[]>('trainings').then(res => res.data)
	}), 
}

export const sessionReportAdaptor = createEntityAdapter<Session>({
	selectId: model => model.id
})

export const groupsSlice = createSlice({
	name: 'groups',
	initialState: {
		groups: <Group[]>[],
		downloads: <DownloadReportItem[]>[],
		trainings: <Training[]>[],
		activeGroup: {
			groupId: <string | undefined> undefined,
			hospitalNames: <string[]>[],
			sessionReportList: sessionReportAdaptor.getInitialState()
		}
	},
	reducers: {
		setActiveGroup: (state, action: PayloadAction<string>) => {

			// Do not change to self, to avoid clearing important data
			const isTargetGroupAlreadyActive = state.activeGroup.groupId == action.payload
			const isValidGroup = state.groups.some(g => g.id == action.payload)

			if (!isTargetGroupAlreadyActive && isValidGroup) {
				state.activeGroup = {
					groupId: action.payload,
					hospitalNames: [],
					sessionReportList: sessionReportAdaptor.getInitialState()
				}

				// Save it to load in future sessions
				saveActiveGroup(action.payload)
			}
		}
	},
	extraReducers: builder => builder

		// Fetch all groups
		.addCase(groupActionsAsync.list.fulfilled, (state, action) => {
			const groups = action.payload

			state.groups = groups

			// if (process.env.REACT_APP_USE_FAKE_DATA) {
			// 	state.downloads = generateFakeDownloads(groups.map(g => g.id))
			// }

			if (groups.length > 0) {
				let groupId = loadActiveGroup()

				// - We have a groupId saved?
				// - The saved groupId exists in the list of groups?
				const isValidGroupId = state.groups.some(g => g.id === groupId)
				
				if (!groupId || !isValidGroupId) {
					groupId = groups[0].id
				}
				
				state.activeGroup = {
					groupId,
					hospitalNames: [],
					sessionReportList: sessionReportAdaptor.getInitialState()
				}
			}
		})

		.addCase(groupActionsAsync.listHospitals.fulfilled, (state, action) => {
			state.activeGroup.hospitalNames = action.payload
		})

		.addCase(groupActionsAsync.fetchReports.fulfilled, (state, action) => {
			
			// if (sessionReportAdaptor.getSelectors().selectTotal(state.activeGroup.sessionReportList) == 0) {
			// 	const fakeSession = generateFakeSession(100)
			// 	sessionReportAdaptor.removeAll(state.activeGroup.sessionReportList)
			// 	sessionReportAdaptor.addMany(state.activeGroup.sessionReportList, fakeSession)	
			// }
			
			sessionReportAdaptor.setMany(state.activeGroup.sessionReportList, action.payload)
		})
		.addCase(groupActionsAsync.fetchTrainingNames.fulfilled, (state, action) => {
			state.trainings = action.payload
		})
		.addCase(groupActionsAsync.fetchDownloadReports.fulfilled, (state, action) => {
			state.downloads = action.payload
		})
})

export const groupActions = {
	...groupActionsAsync,
	...groupsSlice.actions
}


function saveActiveGroup(groupId: string) {
	localStorage.setItem('last-active-group', groupId)
}

function loadActiveGroup() {
	return localStorage.getItem('last-active-group')
}
import {useMemo} from "react"
import {useAppSelector} from "Store/hooks"
import {selectDashboardSessions, selectActiveGroup, selectDownloads} from "Store/selectors"
import {Session} from "Types"
import {computeSessionDuration} from "Util/sessionReportUtils"

export interface SessionTimeReport {
	total: number
	average: number
	min: number
	max: number
}

export interface SessionReport {
	sessionCount: number
	instructorsCount: number
	studentsCount: number
	hospitalCount?: number
	downloadsCount?: number

	sessionTime: SessionTimeReport
}

export default function useSessionCardData(): SessionReport {
	const activeGroup = useAppSelector(selectActiveGroup)
	const sessions = useAppSelector(selectDashboardSessions)
	const downloadReports = useAppSelector(selectDownloads)

	return useMemo(() => {
		
		const uniqueInstructors = new Set(sessions.map(s => s.instructor.name.toLocaleLowerCase()))
		const uniqueStudents = new Set(sessions.map(s => s.student.name.toLocaleLowerCase()))
		const uniqueHospitalNames = new Set(sessions.map(s => s.student.hospitalName.toLocaleLowerCase()))

		return {
			sessionCount: sessions.length,
			instructorsCount: uniqueInstructors.size,
			studentsCount: uniqueStudents.size,

			hospitalCount: uniqueHospitalNames.size,

			sessionTime: computeSessionTime(sessions),
			downloadsCount: downloadReports
				.filter(d => d.groupId === activeGroup?.id)
				.length
		}
	}, [sessions, downloadReports])
}

function computeSessionTime(sessionList: Session[]) {
	if (sessionList.length === 0) {
		return {
			total: 0,
			average: 0,
			min: 0,
			max: 0
		}
	}

	const sessionTime = sessionList.reduce((result, session) => {
		const duration = computeSessionDuration(session)

		result.total += duration
		result.min = Math.min(result.min, duration)
		result.max = Math.max(result.max, duration)

		return result
	}, {min: Number.MAX_VALUE, max: Number.MIN_VALUE, total: 0})

	const average = sessionTime.total / sessionList.length

	return {average, ...sessionTime}
}

function emptyResult(): SessionReport {
	return {
		sessionCount: 0,
		instructorsCount: 0,
		studentsCount: 0,
		hospitalCount: 0,
		sessionTime: {
			total: 0,
			average: 0,
			max: 0,
			min: 0
		}
	}
}
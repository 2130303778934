import Chart, {TooltipItem} from "chart.js/auto"
import Panel from "Components/Panel"
import Enumerable from "linq"
import {DateTime} from "luxon"
import {createRef, useEffect} from "react"
import {DashboardFilterPeriod} from "Store/dashboardFiltersSlice"
import {AppState, useAppSelector} from "Store/hooks"
import {selectDashboardSessions, selectTrainings} from "Store/selectors"
import {Session} from "Types"
import {trelloColors} from "Util/colors"
import {computeSessionDuration} from "Util/sessionReportUtils"

export default function TrainingDurationChartPanel() {

	const canvasRef = createRef<HTMLCanvasElement>()
	const dataset = useAppSelector(selectChartDataset)
	const trainings = useAppSelector(selectTrainings)

	useEffect(() => {
		const canvas = canvasRef.current
		if (!canvas) return

		const getColor = (() => {
			const flippedColors = [...trelloColors].reverse()
			return (index: number) => flippedColors[index % trelloColors.length]
		})()

		const getDataset = () => {
			if (dataset.length > 0)
				return dataset

			return trainings.map(t => ({
				label: t.name,
				data: []
			}))
		}

		const chart = new Chart(canvas, {
			type: "line",
			options: {
				responsive: true,
				maintainAspectRatio: false,
				layout: {
					padding: 10,
				},
				animation: false,
				scales: {
					y: {
						ticks: {
							callback: (tickValue, index, ticks) => {
								const s = tickValue == 0 ? 'min' : 'mins'
								return tickValue + ` ${s}`
							}
						}
					}
				},
				datasets: {
					line: {
						cubicInterpolationMode: 'monotone',
						tension: 0.4,
						borderWidth: 2,
						borderColor: (ctx, options) => {
							if (ctx.parsed?.y == 0)
								return 'rgba(0,0,0,0)'
							return getColor(ctx.datasetIndex)
						},
						backgroundColor: (ctx, options) => {
							if (ctx.parsed?.y == 0)
								return 'rgba(0,0,0,0)'
							return getColor(ctx.datasetIndex)
						}
					}
				},
				plugins: {
					tooltip: {
						callbacks: {
							label(tooltipItem: TooltipItem<"line">): string | string[] {
								return Math.round(tooltipItem.parsed.y) + ' (min)'
							}
						}
					}
				},
			},
			data: {
				datasets: getDataset()
			}
		})

		return () => chart.destroy()
	}, [canvasRef, dataset, trainings])

	return (
		<Panel title="Training duration">
			<canvas ref={canvasRef}/>
		</Panel>
	)
}

function selectChartDataset(state: AppState) {
	const sessions = selectDashboardSessions(state)
	const period = state.dashboardFilters.period

	const trainingNames = sessions
		.flatMap(s => s.training.name)
		.filter((value, index, array) => array.indexOf(value) === index)

	const trainingsPerDay = groupTrainingsPerDay(sessions)
	const emptyTrainings = generateEmptyTrainings(period, trainingNames)

	// Add training days into the original result array
	emptyTrainings
		// Ignore already added dates
		.filter(t => !trainingsPerDay.some(el => el.isoDate == t.isoDate))
		.forEach(val => trainingsPerDay.push(val))

	return computeDataset(trainingsPerDay)
}

interface DatasetPart {
	label: string
	data: { x: string; y: number }[];
}

function computeDataset(trainingsPerDay: TrainingPerDayGroup[]): DatasetPart[] {
	const sessionsEnumerable = Enumerable.from(trainingsPerDay)

	const trainingNames = sessionsEnumerable
		.selectMany(group => group.trainings.map(t => t.name))
		.distinct()

	const dataPerTrainingName = trainingNames
		.toDictionary(
			name => name,
			name => sessionsEnumerable
				.select(session => ({
					isoDate: session.isoDate,
					duration: Enumerable
						.from(session.trainings)
						.where(training => training.name === name)
						.sum(session => session.duration)
				}))
				.orderBy(el => DateTime.fromISO(el.isoDate))
				.toArray()
		)

	return trainingNames
		.select(label => ({
			label: label,
			data: dataPerTrainingName.get(label).map(value => ({
				x: DateTime.fromISO(value.isoDate).toLocaleString({day: '2-digit', month: 'short'}),
				y: value.duration
			}))
		}))
		.toArray()
}


interface TrainingPerDayGroup {
	isoDate: string
	trainings: { duration: number; name: string }[];
}

function groupTrainingsPerDay(sessionList: Session[]): TrainingPerDayGroup[] {

	const minify = (session: Session) => ({
		date: DateTime.fromISO(session.openedAt).toISODate(),
		name: session.training.name,
		duration: computeSessionDuration(session)
	})

	const groupTrainingsPerName = (sessionsPerDate: Enumerable.IEnumerable<ReturnType<typeof minify>>) =>
		sessionsPerDate
			.groupBy(
				session => session.name,
				session => session.duration,
				(trainingName, trainingDurations) => ({
					name: trainingName,
					duration: trainingDurations.sum()
				})
			)
			.toArray()

	return Enumerable
		.from(sessionList)
		.select(minify)
		.groupBy(
			session => session.date,
			session => session,
			(isoDate, sessionsPerDate) => ({
				isoDate,
				trainings: groupTrainingsPerName(sessionsPerDate)
			})
		)
		.toArray()
}

function generateEmptyTrainings(period: DashboardFilterPeriod, trainingNames: string[]): TrainingPerDayGroup[] {

	const emptyTrainings = trainingNames.map(name => ({name, duration: 0}))

	const begin = DateTime.fromISO(period.begin)
	const totalDays = DateTime
		.fromISO(period.end)
		.plus({day: 1})
		.diff(begin)
		.as('days')

	return Enumerable
		.range(0, totalDays)
		.select(day => begin.plus({day}))
		.select(date => ({
			isoDate: date.toISODate(),
			trainings: emptyTrainings
		}))
		.toArray()
}
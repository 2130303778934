import Table from "Components/Table";

import {AppState, useAppSelector} from "Store/hooks";
import {installerAdapter} from "Store/installersSlice"

import DefaultPageTemplate from "Templates/Default";
import InstallerPageRow from "./InstallerPageRow"
import {Styles} from "./styles"

export default function InstallersPage() {
	
	const installers = useAppSelector(selectInstallers)
	
	return (
		<DefaultPageTemplate title="Installers" verticalExpand>
			<Styles>
				<h1 className='t-center'>Manage Installers </h1>

				<Table className='table--root'>
					<thead>
						<tr>
							<th className="c-xr version">   Version     </th>
							<th className="c-xr commit-id"> Commit ID   </th>
							<th className="c-xr date">      Upload Date </th>
							<th className="c-xr status">    Status      </th>
						</tr>
					</thead>

					<tbody>
						{
							installers
								.map(installer => (
									<InstallerPageRow key={ installer.version } data={ installer }/>
								))
						}
					</tbody>
				</Table>
			</Styles>
		</DefaultPageTemplate>
	)
}

const selectInstallers = (state: AppState) => installerAdapter.getSelectors().selectAll(state.installers)
import {createPortal} from "react-dom";
import NotificationErrorPopupItem from "./NotificationErrorPopupItem";
import styled from "styled-components";
import {useAppSelector} from "../Store/hooks";

const Style = styled.div`
	position: fixed;
	
	right: 0;
	top: 0;
	z-index: 999;
`

export default function NotificationPopupList() {

	const notifications = useAppSelector(state => Object.values(state.notification.entities))

	return createPortal(
			<Style>

				{
					notifications.map(n => n && (
						<NotificationErrorPopupItem key={n.id} notification={n}/>
					))
				}
			</Style>

		// @ts-ignore
		, document.getElementById('notification-popup_root'))
}
import styled from "styled-components"

export const Styles = styled.div`
	
	// box-shadow is being defined by DefaultPageTemplate
	// but we don't have a background here, making it look strange
  box-shadow: none !important;

	.filter-controls {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		
		
        gap: 1.4rem;
        margin-bottom: 0.5rem;
	}
	
	.card-list {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		margin-top: 2.2rem;
		gap: 0.5rem;
		justify-content: center;
	}
	
	.graph-list {
		margin-top: 1rem;
		
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
		justify-content: center;
		gap: 0.5rem;
		
		.panel-root {
			// https://stackoverflow.com/a/65984889/7043192
			// I don't know why, but this fixed the panel not shrinking after expanding on window resize
			min-width: 0;
		}

    .panel-content {
	    position: relative;
	    height: 400px;
    }
	}
`
import {createSelector} from "@reduxjs/toolkit"
import Enumerable from "linq"
import {DateTime} from "luxon"
import {ReportFilterFields} from "Pages/ReportsPage/useReportFilter"
import {sessionReportAdaptor} from "Store/groupsSlice"
import {AppState} from "Store/hooks"
import {userAdapter} from "Store/usersSlice"

export const selectActiveGroup = (state: AppState) => {
	const groups = state.groups.groups
	const activeGroupId = state.groups.activeGroup.groupId

	return groups.find(value => value.id === activeGroupId)
}

const sessionSelectors = sessionReportAdaptor.getSelectors((state: AppState) => state.groups.activeGroup.sessionReportList)
const usersSelectors = userAdapter.getSelectors((state: AppState) => state.users)

export const selectSessions = (state: AppState) => sessionSelectors.selectAll(state)

export const selectGroups = (state: AppState) => state.groups.groups

export const selectDownloads = (state: AppState) => state.groups.downloads

export const selectSessionsFiltered = createSelector(
	(state: AppState) => selectSessions(state),
	(_: any, filters: Partial<ReportFilterFields>) => filters,

	(sessions, filters) => {
		return sessions
			.filter(s => !filters.trainingId ? true : s.training.id == filters.trainingId)
			.filter(s => !filters.hospitalName ? true : s.student.hospitalName == filters.hospitalName)
			.filter(s => !filters.instructorId ? true : s.instructor.id == filters.instructorId)
			.filter(s => !filters.studentSearch ? true : s.student.name.toLocaleLowerCase().includes(filters.studentSearch.toLocaleLowerCase()))
			.filter(s => {
				if (!filters.period) {
					return true
				}

				const begin = DateTime.fromISO(filters.period.begin).startOf('day')
				const end = DateTime.fromISO(filters.period.end).endOf('day')
				const interval = begin.until(end)

				const sessionDate = DateTime.fromISO(s.openedAt)

				return interval.contains(sessionDate)
			})
	}
)

export const selectDashboardSessions = (state: AppState) => selectSessionsFiltered(state, state.dashboardFilters)

export const selectTrainings = (state: AppState) => state.groups.trainings

export const selectHospitals = (state: AppState) => {
	const sessions = selectSessions(state)

	return Enumerable
		.from(sessions)
		.select(s => s.student.hospitalName)
		.distinct()
		.toArray()
}

export const selectUniqueInstructors = (state: AppState) => {
	const sessions = selectSessions(state)

	return Enumerable
		.from(sessions)
		.select(s => s.instructor)
		.distinct(inst => inst.id)
		.toArray()
}

export const selectLoggedUser = (state: AppState) => state.users.me

export const selectUsers = (state: AppState) => usersSelectors.selectAll(state)
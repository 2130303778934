import {useContext} from "react";
import styled from "styled-components"
import {IconCheck, IconDelete, IconEdit, IconPlus, IconTrashBin} from "Icons";
import {User} from "Types"
import {UsersTableRowContext} from "."

interface DrawActionButtonsProps {
	user: User
	loggedUser: Pick<User, 'id' | 'permission'>

	deleteUser: (user: User) => void
	submitEdition: () => void
	cancelEdition: () => void
}

export function UserTableRowActionButtons(props: DrawActionButtonsProps) {

	const tableRow = useContext(UsersTableRowContext)


	const disableEditionForUser = (() => {
		// Do not let the user edit himself
		if (props.user.id == props.loggedUser.id) return true
		
		// Disable edition of users with greater permission level than you 
		if (props.user.permission > props.loggedUser.permission) return true
		
		return false;
	})()

	// Create user mode
	if (tableRow.displayMode == "create") {
		return (
			<MyButton type="button" onClick={props.submitEdition}>
				<IconPlus className="fill-blue1"/>
			</MyButton>
		)
	}

	// Edit user mode, with edition disabled
	if (!tableRow.isEditing) {
		return (
			<MyButton
				data-testid="edit-button"
				disabled={disableEditionForUser}
				onClick={() => tableRow.setIsEditing(true)}
			>
				<IconEdit className="fill-blue1"/>
			</MyButton>
		)
	}

	// Edit user mode, with edition enabled
	return (
		<div className='action-buttons-container--edit-mode'>
			<MyButton type='button' onClick={() => props.deleteUser(props.user)}>
				<IconTrashBin title="Delete user" className="fill-red"/>
			</MyButton>

			<MyButton type='button' onClick={props.cancelEdition}>
				<IconDelete title="Cancel edition" className="fill-blue0"/>
			</MyButton>

			<MyButton type='submit' onClick={props.submitEdition}>
				<IconCheck title="Save changes" className="fill-green action-save"/>
			</MyButton>
		</div>
	)
}

const MyButton = styled.button.attrs({className: 'default pointer'})`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 25px;

  border: none;
  background: none;
`
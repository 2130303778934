import useNotification from "Hooks/useNotification.hook";
import {IconWarning} from "Icons";
import styled, {keyframes} from "styled-components";
import {INotification} from "Store/utility/notification.slice";

const shit = keyframes`
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
`

const Style = styled.div`
  position: relative;

  margin: 1em;
  width: 400px;

  background-color: #cf4b4c;

  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;

  padding: 0.25rem 0.5rem;

  p {
    text-align: center;
    color: white;
  }

  svg {
    width: 50px;
    height: 50px;
  }

  .close-button {
    cursor: pointer;
    position: absolute;

    top: 0;
    right: 0;

    text-align: center;

    width: 1.5em;
    height: 1.5em;
  }

  .progress {
    position: absolute;
    bottom: 0;

    width: 100%;
    height: 2px;

    background-color: white;

    ::after {
      content: '';
      display: block;

      width: 50%;
      height: 100%;

      animation: ${shit} 3s linear 1;
      background-color: red;
    }
  }
`

export default function NotificationErrorPopupItem(props: { notification: INotification }) {

    const notification = useNotification();
    const clear = () => notification.remove(props.notification.id)

    return (
        <Style>
			<span className="close-button" onClick={clear}>
				✖
			</span>

            <IconWarning/>

            <p className="label"> {props.notification.message} </p>

            <div className="progress"/>
        </Style>
    )
}
import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {DateTime} from "luxon"

export interface DashboardFilterPeriod {
	begin: string
	end: string
}

export const dashboardFiltersSlice = createSlice({
	name: 'dashboard-filters',
	initialState: {
		trainingId: '',
		hospitalName: '',
		period: {
			begin: DateTime.now().minus({month: 1}).toISODate(),
			end: DateTime.now().toISODate()
		} as DashboardFilterPeriod,
	},
	reducers: {
		setHospitalName: (state, action: PayloadAction<string>) => {
			state.hospitalName = action.payload
		},
		setTrainingId: (state, action: PayloadAction<string>) => {
			state.trainingId = action.payload
		},
		setPeriod: (state, action: PayloadAction<DashboardFilterPeriod>) => {
			state.period = action.payload
		}
	}
})

export const dashboardFilterActions = dashboardFiltersSlice.actions
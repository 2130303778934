import {DateTime} from "luxon"
import {groupActions} from "Store/groupsSlice"
import {selectActiveGroup} from "Store/selectors"
import create from "zustand"
import {store} from "Store/store"

export type ReportFilterArg = "instructorId" | "trainingId" | "hospitalName" | "studentSearch"

export interface ReportFilterFields extends Record<string, any>  {
	instructorId: string
	trainingId: string
	hospitalName: string
	studentSearch: string

	period: {
		begin: string
		end: string
	}
}

interface ReportFilter extends ReportFilterFields {
	setFilter: (arg: ReportFilterArg, value: string) => void
	setPeriod: (begin: string, end: string) => void
	hasFilter: (arg: ReportFilterArg) => boolean
	getFilter: (arg: ReportFilterArg) => string
}

const useReportFilter = create<ReportFilter>((set, get) => ({
	instructorId: '',
	trainingId: '',
	hospitalName: '',
	studentSearch: '',

	period: {
		begin: DateTime.now().minus({month: 1}).toISODate(),
		end: DateTime.now().toISODate()
	},

	setFilter: (arg, value) => {
		set(state => state[arg] = value)
	},
	setPeriod: (begin, end) => {

		const period = {begin, end}
		set({period})
		
		store.dispatch(groupActions.fetchReports({
			groupId: selectActiveGroup(store.getState())!.id,
			fromDate: period.begin,
			toDate: period.end
		}))
	},
	
	getFilter(arg) {
		return get()[arg];
	},
	
	hasFilter(arg) {
		const filter = get()
		return !!filter[arg]
	}
}))

export default useReportFilter
import React from "react"

import UsersPage from "Pages/UsersPage"
import ReportsPage from "Pages/ReportsPage"
import DashboardPage from "Pages/DashboardPage"
import InstallersPage from "Pages/InstallersPage"

import {IconDashboard, IconInstaller, IconLicense, IconUsers} from "Icons"
import {UserPermission} from "Types"

interface RouteItemDescription {

	path: string

	Page: any
	Icon: any

	minPermission: UserPermission
}

export const routeItemsDescriptor: RouteItemDescription[] = [
	{
		path: '/dashboard',
		Page: DashboardPage,
		Icon: IconDashboard,
		minPermission: UserPermission.Manager
	},
	{
		path: '/users',
		Page: UsersPage,
		Icon: IconUsers,
		minPermission: UserPermission.Manager
	},
	{
		path: '/reports',
		Page: ReportsPage,
		Icon: IconLicense,
		minPermission: UserPermission.Instructor
	},
	{
		path: '/installers',
		Page: InstallersPage,
		Icon: IconInstaller,
		minPermission: UserPermission.Support
	}
]

export function getRouteItemsForPermission(permission: UserPermission) {
	return routeItemsDescriptor.filter(item => permission >= item.minPermission)
}
import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template: 
		"header header" 3.9rem
		"sidebar content" auto 
		/ min-content auto;

  // Force the page to use the whole vertical space
  min-height: 100vh;

  #template-header {
    grid-area: header;
  }

  #template-sidebar {
    grid-area: sidebar;
  }

  #template-content {
    grid-area: content;

    padding: 2em 1em;

    &[data-expand-vertical="true"] {
      display: grid;
      grid-template-rows: min-content auto;
    }

    > :not(.content-header) {
      margin: 2rem 4.5rem auto;
	    
	    border-top: 2rem;
      border-radius: 4px;
	    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.05);
    }

    .content-header {
      font-size: 3rem;
      margin-left: 4.25rem;
    }
  }

	// Draw gray content over everything to block interaction while the ProgressBar is animating
  &[data-block-interaction="true"]::after {
    content: '';
	  z-index: 100;
    position: fixed;

    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.25);
  }
`
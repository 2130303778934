import BetterTable, {BetterTableHeader} from "Components/BetterTable"
import Table from "Components/Table";
import useLoadingProgress from "Hooks/useLoadingProgress"
import useWarningPopup from "Hooks/useWarningPopup.hook"
import {IconSearch} from "Icons"
import UsersSearchField from "Pages/UsersPage/UsersSearchField"
import {useState} from "react";
import {useAppDispatch, useAppSelector} from "Store/hooks";
import {selectActiveGroup, selectLoggedUser, selectUsers} from "Store/selectors"
import {userActions} from "Store/usersSlice"
import DefaultPageTemplate from "Templates/Default";
import {User, UserPermission} from "Types";
import {UsersPageStyles} from "./UsersPage.styles";
import UserTableRow from "./UsersTableRow";

export default function UsersPage() {

	const [searchString, setSearchString] = useState('')

	const usersList = useAppSelector(selectUsers)
	const loggedUser = useAppSelector(selectLoggedUser)
	const activeGroup = useAppSelector(selectActiveGroup)

	const dispatch = useAppDispatch()
	const warningPopup = useWarningPopup()
	const loadingProgress = useLoadingProgress()

	function deleteUser(user: User) {
		warningPopup.open({
			message: (
				<>
					<p>You are about to delete {user.displayName}</p>
					<p>Are you sure?</p>
				</>
			),
			onSuccess: () => {
				loadingProgress.start()
				dispatch(userActions.delete({userId: user.id}))
					.finally(loadingProgress.stop)
			}
		})
	}

	if (!loggedUser || !activeGroup)
		return null

	return (
		<DefaultPageTemplate title="Users" verticalExpand>
			<UsersPageStyles>
				<UsersSearchField value={searchString} onChange={setSearchString}/>

				<BetterTable
					data={
						usersList
							.filter(user => searchContainsUser(user, searchString))
							.map(sortUserProperties)
					}

					customItemDrawer={(user, index) => (
						<UserTableRow
							key={user.id}
							displayMode='edit' activeGroupId={activeGroup.id} loggedUser={loggedUser}
							user={user} deleteUser={deleteUser}
						/>
					)}

					drawBeforeItems={() => (
						<UserTableRow
							displayMode="create" activeGroupId={activeGroup.id} loggedUser={loggedUser}
							user={emptyUser()} deleteUser={deleteUser}
						/>
					)}
				>

					<BetterTableHeader for="id" hidden/>
					<BetterTableHeader for="group" hidden/>

					<BetterTableHeader for="displayName" title="Name"/>
					<BetterTableHeader for="email" title="Email"/>
					<BetterTableHeader for="permission" title="Permission"/>
					<BetterTableHeader for="isActive" title="Status"/>

				</BetterTable>

			</UsersPageStyles>
		</DefaultPageTemplate>
	)

}

function searchContainsUser(user: User, searchString: string): boolean {

	if (searchString.length == 0)
		return true

	if (user.email.includes(searchString))
		return true;

	if (user.displayName.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()))
		return true

	return false;
}

// The SortableTable will draw the properties using the same order they were given in the object
// sadly, I don't have a better way to change the order without recreating the object. :(
function sortUserProperties({displayName, email, permission, isActive, ...s}: User): User {
	return {
		displayName,
		email,
		permission,
		isActive,
		...s
	}
}


const emptyUser = (): User => ({
	id: '',
	displayName: '',
	email: '',
	group: '',
	permission: UserPermission.Instructor,
	isActive: true,
});
import IconSearch from "Assets/icons/Search_ICON.svg";
import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-rows: min-content min-content auto;

  padding: 2rem;
  min-height: 92%;

  font-size: 1.2rem;
  background-color: var(--big-panel-background);
  
  .report-filters {
    display: flex;
    gap: 1.4rem;
  }
  
  label span {
    font-size: 1rem;
  }

  .search-field {
    position: relative;

    width: 50ch;
    margin-top: 1em;
    margin-bottom: 0.25em;

    input {
      width: 100%;
      font-size: 1rem;
    }

    ::after {
      content: url(${IconSearch});

      display: block;
      position: absolute;

      top: 0.1em;
      right: 0;

      width: 20px;
      height: 20px;
    }
  }
`
import styled from "styled-components"

export const Styles = styled.div`

  background-color: var(--big-panel-background);

  display: grid;
  min-height: 92%;
  grid-template-rows: min-content auto;

  .table--root {
    padding: 2rem;
    font-size: 1.25em;

    select {
      font-size: 1em;
      padding: 1px;
    }

    th, td {
      :first-child {
        padding-left: 5rem;
      }

      :last-child {
        padding-right: 1rem;
      }
      
      padding: 1px;
      font-size: 1rem;
    }

    .commit-id {
      width: 40ch;
    }

    .action {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 2rem;
    }
  }
`
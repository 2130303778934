import useLoadingProgress from "Hooks/useLoadingProgress"
import useWarningPopup from "Hooks/useWarningPopup.hook"
import {IconDelete} from "Icons"
import {DateTime} from "luxon"
import {ChangeEvent, useEffect} from "react"
import {useAppDispatch} from "Store/hooks"
import {installersActions} from "Store/installersSlice"
import styled from "styled-components"
import {Installer} from "Types"

const RowStyles = styled.tr`
	
	&[data-is-active="false"] .lockable {
    pointer-events: none;
    color: var(--gray2);
    fill: var(--gray2);
	}

  td { text-align: center; }

  .btn-action {
    border: none;
    background-color: transparent;

    cursor: pointer;
    width: 1.5rem;
    font-size: 1.2em;
  }
`

export interface IRowProps {
	data: Installer
}

export default function InstallerPageRow({data}: IRowProps) {

	const progress = useLoadingProgress();
	const dispatch = useAppDispatch();
	const warningPopup = useWarningPopup();

	function onClickDelete() {

		warningPopup.open({
			message: `Removing installer version ${data.version}`,
			challenge: {
				title: 'Type the version number to confirm removal',
				phrase: data.version
			},
			onSuccess: () => {
				progress.start()
				dispatch(installersActions.delete(data.version))
					.finally(progress.stop)
			}
		})
	}

	function onStatusChanged(e: ChangeEvent<HTMLSelectElement>) {
		const isTrue = e.target.value === 'true'

		progress.start()
		dispatch(installersActions.update({
			isActive: isTrue,
			version: data.version
		}))
			.finally(progress.stop)
	}

	return (
		<RowStyles data-is-active={data.isActive}>
			<td className='lockable version'> {data.version} </td>
			<td className='lockable commit-id'> {data.commitId} </td>
			<td className='lockable date'> {DateTime.fromISO(data.uploadDate).toFormat('dd/MM/yyyy')} </td>
			<td className='status'>
				<select className="t-center" value={data.isActive.toString()} onChange={onStatusChanged}>
					<option value="true">Active</option>
					<option value="false">Disabled</option>
				</select>
			</td>

			<td className='action'>
				<IconDelete className="btn-action fill-red" onClick={onClickDelete}/>
			</td>
		</RowStyles>
	)
}
import {TableStyled} from "Components/BetterTable.styles"
import {IconSort} from "Icons"
import React, {createContext, useContext, useEffect, useMemo, useState} from "react"

export type TableDataItem<T extends Object> = Record<string, T>
type DescriptorList = Record<string, TableHeaderDescriptor>
type CustomLineDrawer<T> = (item: T, index: number) => React.ReactNode 

export interface TableProps<T extends Object> {
	className?: string
	data: T[]

	customItemDrawer?: CustomLineDrawer<T>
	children?: React.ReactElement<typeof DrawTableHeader> | React.ReactElement<typeof DrawTableHeader>[] 
	
	drawBeforeItems?: () => React.ReactNode
	drawAfterItems?: () => React.ReactNode
}

interface TableContextProps {
	descriptors: Record<string, TableHeaderDescriptor>
	sortingInfo: SortDescription

	sortBy(key: string): void
	addDescriptor(arg: TableHeaderDescriptor): void
}

export interface SortDescription {
	header: string
	isDescendant: boolean
}

const TableContext = createContext({} as TableContextProps)

export default function BetterTable<T extends Object>(props: TableProps<T>) {
	
	const [descriptorList, setDescriptorList] = useState<DescriptorList>({})
	const [sorting, setSorting] = useState<SortDescription>({header: '', isDescendant: false})

	const addDescriptor = useMemo(() => {
		return (descriptor: TableHeaderDescriptor) => {
			setDescriptorList(prev => {
				const newItems = {...prev}
				newItems[descriptor.for] = descriptor

				return newItems
			})
		}
	}, [setDescriptorList])

	const sortBy = useMemo(() => {
		return (header: string) => {
			setSorting(prevState => ({
				header: header,
				isDescendant: prevState.header == header ? !prevState.isDescendant : false
			}))
		}
	}, [setSorting])
	
	const canRenderContent = useMemo(() => {
		return props.children && Object.entries(descriptorList).length > 0
	}, [props.children, descriptorList])

	function sorter(a: TableDataItem<T>, b: TableDataItem<T>): number {

		if (!sorting.header)
			return 0

		const propA = a[sorting.header]
		const propB = b[sorting.header]
		const order = sorting.isDescendant ? -1 : 1;

		const sortingMethod = descriptorList[sorting.header]?.sorter ?? genericSorting
		
		return sortingMethod(propA, propB) * order
	}

	return (
		<TableContext.Provider value={{descriptors: descriptorList, addDescriptor, sortBy, sortingInfo: sorting}}>

			{/*This should not draw anything, it's only for API header initialization purpose*/}
			{ props.children }

			{
				canRenderContent && (
					<TableStyled className={props.className}>
						<DrawTableHeader item={props.data[0]} sorting={sorting} descriptors={descriptorList} onHeaderClick={sortBy}/>

						<tbody>
							{ props.drawBeforeItems?.() }
							
							<DrawTableBody
								data={props.data}
								sortingInfo={sorting}
								descriptors={descriptorList}
								customLineDrawer={props.customItemDrawer}
							/>
	
							{ props.drawAfterItems?.() }
						</tbody>
					</TableStyled>		
				)
			}

		</TableContext.Provider>
	)
}

function DrawTableHeader(props: { 
	item?: TableDataItem<any>,
	descriptors: DescriptorList
	sorting: SortDescription

	onHeaderClick(headerKey: string): void
}) {

	const isSortingDescendant = props.sorting.isDescendant
	const getCustomTitle = (header: string) => props.descriptors[header]?.title
	const columnHasSorting = (header: string) => props.sorting.header == header
	const filterHiddenColumns = (header: string) => !(props.descriptors[header]?.hidden ?? false)

	function generateHeaderItems(headerKeyItems: string[]) {
		return headerKeyItems
			.filter(filterHiddenColumns)
			.map(key => (
				<td key={key}
				    style={{textTransform: 'capitalize'}}
				    onClick={() => props.onHeaderClick(key)}>

					{ getCustomTitle(key) ?? key }

					{
						columnHasSorting(key) && (
							<DrawSortingIcon isDescendant={isSortingDescendant}/>
						)
					}

				</td>
			))
	}

	return (
		<thead>
			<tr>
				{
					generateHeaderItems(
						props.item
							// Draw the default headers using the table "data" property
							? Object.keys(props.item)
							
							// The "data" array is probably empty. 
							// In this case, let's use the header infos provided by our descriptors 
							: Object.values(props.descriptors).map(d => d.for)
					)
				}
			</tr>
		</thead>
	)
}

function DrawTableBody (props: {
	sortingInfo: SortDescription
	descriptors: DescriptorList
	data: TableDataItem<any>[]

	customLineDrawer?: CustomLineDrawer<any>
}) {

	function sorter(a: TableDataItem<any>, b: TableDataItem<any>): number {

		if (!props.sortingInfo.header)
			return 0

		const propA = a[props.sortingInfo.header]
		const propB = b[props.sortingInfo.header]
		const order = props.sortingInfo.isDescendant ? -1 : 1;

		const sortingMethod = props.descriptors[props.sortingInfo.header]?.sorter ?? genericSorting

		return sortingMethod(propA, propB) * order
	}


	return (
		<>
			{
				props.data
					// @ts-ignore
					.sort(sorter)
					.map((item, index) => (

						props.customLineDrawer
							? props.customLineDrawer(item, index)
							: <DrawTableRow key={index} rowIndex={index} item={item}/>
					))
			}
		</>
	)
}

function DrawSortingIcon(props: { isDescendant: boolean }) {
	return props.isDescendant 
		? <IconSort className='sorting-arrow' data-sort={"descendant"} />
		: <IconSort className='sorting-arrow' data-sort={"ascendant"} />
}

function DrawTableRow(props: { item: TableDataItem<any>, rowIndex: number }) {
	return (
		<tr>
			{
				Object
					.keys(props.item)
					.map((key, columnIndex) => (
						<DrawTableRowItem
							key={`${(props.rowIndex)} - ${columnIndex}`}
							header={key}
							data={props.item[key]}
						/>
					))
			}
		</tr>
	)
}

function DrawTableRowItem(props: { header: string, data: any }) {

	const tableContext = useContext(TableContext)

	const getCustomDrawer = (data: any) => tableContext.descriptors[props.header]?.draw?.(data)

	// Can't render objects, only plain data (like strings, numbers and stuff)
	if (typeof props.data == 'object') {
		return (
			<td> {"{{object}}"}  </td>
		)
	}

	return (
		<td>
			{ getCustomDrawer(props.data) ?? props.data }
		</td>
	)
}

export interface TableHeaderDescriptor {
	for: string
	hidden?: boolean

	title?: React.ReactNode
	draw?: (item: any) => React.ReactNode
	sorter?: (a: any, b: any) => number
}

// The only purpose for this, is to add custom functionality for the Table
export function BetterTableHeader(props: TableHeaderDescriptor) {
	const tableContext = useContext(TableContext)

	useEffect(
		() => tableContext.addDescriptor(props),
		[]
	)

	return null
}

function genericSorting(a: any, b: any) {
	switch (typeof a) {
		case 'string':  return a.localeCompare(b)
		case "number":  return a > b ? 1 : -1
		default:        return 0
	}
}
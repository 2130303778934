import styled from "styled-components";

export const Style = styled.div`
  --arrow-scale: 2.75;

  display: flex;
  justify-self: center;

  position: relative;

  &[data-enable-dropdown="true"] {
    cursor: pointer;
  }

  :not(:focus-within) {
    .other-orgs {
      display: none;
    }

    .active-org .arrow {
      transform: rotate(0) scale(var(--arrow-scale));
    }
  }

  .active-org {
    display: flex;
    justify-content: space-between;

    width: 23em;
    padding: 0.15em 0.75em;
    border-radius: 0.15em;

    background-color: var(--alt-white);

    .arrow {
      user-select: none;
      align-self: center;
      transform: rotate(180deg) scale(var(--arrow-scale));
    }
  }

  .other-orgs {
    position: absolute;

    //top: 2em;
    top: 100%;
    left: 7%;
    right: 0;
    padding: 0.25em 1em;

    z-index: 999;

    border-radius: 0.35em;

    background-color: var(--alt-white);
    outline: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.28);

    > * {
      margin: 0.25em 0;
      width: 100%;
    }
  }
`

export const ItemStyles = styled.div`
  display: flex;
  gap: 0.5em;
  align-items: center;

	cursor: pointer;
	font-size: 1.25em;

	img {
		width: 1.25em;
		height: 1.25em;
	}
	
	span {		
		overflow: hidden;
		white-space: nowrap;
	}
`
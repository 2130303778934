import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
	
  // I can't make the table grow vertically without stretching the rows
  // neither apply a margin on it... tables are weird
  .table-wrapper {
    height: 100%;
    //background-color: var(--table-background);
    //outline: 1px solid rgba(0, 0, 0, 0.3);
	  border-radius: 2px;

    table {
      width: 100%;
      border-collapse: collapse;

      //background-color: var(--table-background);
	    
	    th { color: var(--table-header); }
      td { 
	      text-align: center;
        color: var(--table-content);
      }
	    tr { line-height: 1.75em; }
      thead tr { border-bottom: var(--table-row-line); }
      //tbody tr { border-bottom: var(--table-row-line); }
    }
  }
`

export interface ITableProps extends React.HTMLAttributes<HTMLDivElement> {
}

export default function Table(props: ITableProps) {

	const {children, ...rest} = props

	return (
		<Wrapper {...rest}>
			<div className="table-wrapper">
				<table>
					{ children }
				</table>
			</div>
		</Wrapper>
	)
}
import styled from "styled-components"

export const TableStyled = styled.table`
	
	--border-color: rgba(0, 0, 0, 0.2);
  
	// This enables the possibility to draw a line under of each row
  border-collapse: collapse;

  td { text-align: center; }

  thead {
	  
	  tr {

      // Draw line under this row
      border-bottom: 1px solid var(--border-color);
	  }
	  
    td {
      position: relative;
      cursor: pointer;

      color: var(--table-header);
      white-space: nowrap;

      // Add a visual separator for each table header item (but not the last one)
      :not(:last-child) { 
	      border-right: 1px solid var(--border-color); 
      }

      .sorting-arrow {
        position: absolute;

        top: 10%;
        left: 50%;

        fill: var(--border-color);

        transform: translate(-50%, -50%);

        &[data-sort = "ascendant"] { transform: translate(-50%, -50%) rotateZ(180deg); }
      }
    }
  }

  tbody td {
    color: var(--gray2-alt);
    opacity: 0.9;
	  
	  //text-align: left;
	  
	  padding: 0 10px;
  }

`
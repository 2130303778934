import {AppState, useAppSelector} from "Store/hooks"
import {UserPermission} from "Types"

export function usePermission(): UserPermission;
export function usePermission(expectedPermission: UserPermission, exact?: boolean): boolean | undefined;

export function usePermission(expectedPermission?: UserPermission, exact: boolean = false): UserPermission | (boolean | undefined) {
	const permission = useAppSelector((state: AppState) => state.users.me?.permission)

	if (!expectedPermission)
		return permission ?? UserPermission.None

	if (!permission)
		return undefined

	if (exact)
		return permission === expectedPermission

	return permission >= expectedPermission;
}
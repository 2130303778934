import Enumerable from "linq"
import {selectActiveGroup} from "Store/selectors"
import {Group} from "Types"
import {groupActions} from "Store/groupsSlice"
import {useAppDispatch, useAppSelector} from "Store/hooks";
import {ItemStyles, Style} from "./GroupSelectorDropdown.styles";

export default function GroupSelectorDropdown() {

	const groups = useAppSelector(state => state.groups.groups);
	const activeGroup = useAppSelector(selectActiveGroup)

	const enableDropdown = groups.length > 1

	return (
		<Style id='dropdown' tabIndex={-1} data-enable-dropdown={enableDropdown}>
			<div className="active-org">
				{
					!activeGroup
						? <DropdownItemEmpty/>
						: <DropdownItem {...activeGroup} isActive={true} disableClick/>
				}

				{
					enableDropdown && (
						<span className="arrow c-main">&#9662;</span>
					)
				}
			</div>

			<DrawDropdownListItems enable={enableDropdown} groups={groups}/>

		</Style>
	)
}

function DrawDropdownListItems(props: { enable: boolean, groups: Group[] }) {

	const activeGroup = useAppSelector(selectActiveGroup)

	if (!props.enable)
		return null;

	return (
		<div className="other-orgs">
			{
				// WTF, why native groups.sort does not work here???
				Enumerable
					.from(props.groups)
					.orderBy(g => g.name)
					.select(org => (
						<DropdownItem key={org.id} {...org} isActive={org.id === activeGroup?.id}/>
					))
					.toArray()
			}

		</div>
	)
}

function DropdownItem(props: Group & { isActive?: boolean, disableClick?: boolean }) {

	const dispatch = useAppDispatch()
	const onClickItem = (e: React.MouseEvent<HTMLDivElement>) => {

		if (props.disableClick)
			return

		e.currentTarget.blur()
		dispatch(groupActions.setActiveGroup(props.id))
	}

	return (
		<ItemStyles tabIndex={-1} onClick={onClickItem}>
			<img src={'data:image/png;base64,' + props.thumbnailBase64} alt=''/>
			<span className={props.isActive ? 'c-main' : ''}> {props.name} </span>
		</ItemStyles>
	)
}

function DropdownItemEmpty() {
	return (
		<ItemStyles>
			<img src="https://via.placeholder.com/200" alt="O"/>
			<span> EMPTY </span>
		</ItemStyles>
	)
}
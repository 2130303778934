import {NavigationClient} from "@azure/msal-browser"
import {NavigateFunction} from "react-router-dom"

export default class CustomNavigationClient extends NavigationClient {
	private readonly navigate: NavigateFunction;

	constructor(navigate: NavigateFunction) {
		super();
		this.navigate = navigate;
	}

	// This function will be called anytime msal needs to navigate from one page in your application to another
	async navigateInternal(url: string, options: { noHistory: any; }) {
		// url will be absolute, you will need to parse out the relative path to provide to the history API
		const relativePath = url.replace(window.location.origin, '');
		// if (options.noHistory) {
		// 	this.history.replace(relativePath);
		// } else {
		// 	this.history.push(relativePath);
		// }

		this.navigate(relativePath)

		return false;
	}
}
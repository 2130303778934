import React from "react";
import {useNavigate} from "react-router-dom"
import styled from "styled-components";

import LoadingProgressBar from "./LoadingProgressBar";
import GroupSelectorDropdown from "./GroupSelectorDropdown";
import UserProfileImageAndLoginInfo from "./UserProfileImageAndLoginInfo";
import DownloadButtons from "./DownloadButtons"

import iconLogo from 'Assets/icons/BD_LOGO.png'

const CompanyLogo = styled.img.attrs({src: iconLogo, id: 'comp-logo'})`
	width: 120px;
	cursor: pointer;
`

const Styles = styled.header`
  // Relative position is being used by "progress bar"
  position: relative;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: space-between;

  padding-left: 0.8em;
  padding-right: 0.8em;

  // This value is being driven by the Template
  //height: 3em;
  background-color: var(--blue2);
`

export default function HeaderBar(props: { id?: string }) {
	
	const navigate = useNavigate()

	return (
		<Styles id={ props.id }>

			<LoadingProgressBar />

			<CompanyLogo onClick={() => navigate('/dashboard')}/>
			<GroupSelectorDropdown/>
			<div id='profile-info' style={{justifySelf: "flex-end", display: 'flex', gap: '1em'}}>
				<DownloadButtons/>
				<UserProfileImageAndLoginInfo/>
			</div>
		</Styles>
	)
}
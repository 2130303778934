import React from "react";
import styled from "styled-components";
import useLoadingProgress from "Hooks/useLoadingProgress";

const ProgressBarElement = styled.div<{ animate: boolean }>`

	visibility: ${ ({animate}) => animate ? 'visible' : 'hidden' };
  position: absolute;

  top: 100%;
	left: 0;
	right: 0;
	// For whatever reason, using width to set the size does not work correctly
	// making the bar display a horizontal scroll bar without a reason to display one.
  //width: 100vw;

  ::after {
	  display: block;
    content: '';

    height: 2px;
	  
	  // Must draw on top of "gray loading screen"
	  z-index: 200;

    background-color: var(--orange0);

    animation: loading 3s linear infinite;
    transform-origin: left center;
  }

  @keyframes loading {
    0% 		{	transform: translateX(0%) 	scaleX(0);		}
    20% 	{	transform: translateX(0%) 	scaleX(0.2);	}
    80% 	{	transform: translateX(80%) 	scaleX(0.2);	}
    100% 	{	transform: translateX(100%) scaleX(0);		}
  }
`

export default function LoadingProgressBar() {
	const loadingProgress = useLoadingProgress();
	return <ProgressBarElement animate={ loadingProgress.isLoading }/>
}
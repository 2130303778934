import {Field, Formik, FormikHelpers} from "formik";
import React, {createContext, useMemo, useState} from "react";
import {connect} from "react-redux"
import {AppDispatch} from "Store/hooks";

import {userActions} from "Store/usersSlice"
import {globalLoadingActions} from "Store/utility/loadingProgress.slice"

import {User} from "Types"

import {TableRowStyles} from "./UsersTableRow.styles";
import {DrawUserStatus} from "./DrawUserStatus"
import {UserTableRowActionButtons} from "Pages/UsersPage/UsersTableRow/UserTableRowActionButtons";
import {DrawPermissionDropdown} from "./DrawPermissionDropdown"

export type DisplayMode = 'create' | 'edit'
export type ViewMode = "edit" | 'view' | 'view-disabled'

interface UserRowContextProps {
	user: User
	displayMode: DisplayMode

	isEditing: boolean
	setIsEditing: (state: boolean) => void
}

interface UserRowProps {
	user: User
	displayMode: DisplayMode

	activeGroupId: string
	loggedUser: Pick<User, 'id' | 'permission'>

	saveChanges: (user: User) => Promise<void>
	deleteUser: (user: User) => void
}

export const UsersTableRowContext = createContext({} as UserRowContextProps)


export function UserTableRow_RAW(props: UserRowProps) {

	const [isEditing, setIsEditing] = useState(false)

	const {displayMode, user} = props

	const viewMode: ViewMode = useMemo(() => {
		if (isEditing || displayMode === 'create')
			return 'edit'

		if (!user.isActive)
			return 'view-disabled'

		return 'view'
	}, [isEditing, displayMode])

	async function handleFormSubmit(values: User, formikHelpers: FormikHelpers<User>) {
		props.saveChanges(values)
			.then(() => setIsEditing(false))
			.finally(() => formikHelpers.setSubmitting(false))
	}

	return (
		<UsersTableRowContext.Provider value={{user, displayMode, isEditing, setIsEditing}}>
			<TableRowStyles className={viewMode}>
				<Formik initialValues={user} onSubmit={handleFormSubmit}>
					{
						/*
						 * Both "displayName" and "email" can only be changed while in creation mode
						 */
					}

					{
						(formikProps) => (
							<>
								{/* Display Name  */}
								<td>
									<Field name="displayName"
									       readOnly={displayMode !== 'create'}
									       tabIndex={displayMode === 'create' ? 0 : -1}
										   placeholder="User name..."
									/>
								</td>

								{/* Email  */}
								<td>
									<Field name="email" type="email" 
									       readOnly={displayMode !== 'create'}
									       tabIndex={displayMode === 'create' ? 0 : -1}
										   placeholder="Email address..."
									/>
								</td>

								{
									/*
									 *	Permission and Status can be changed while in edition mode
									 */
								}

								{/* Permission */}
								<td>
									<DrawPermissionDropdown loggedUser={props.loggedUser} viewMode={viewMode}/>
								</td>

								{/* Status */}
								<td>
									<DrawUserStatus viewMode={viewMode}/>
								</td>

								{/* Action buttons */}
								<td>
									<UserTableRowActionButtons
										user={user} loggedUser={props.loggedUser} deleteUser={props.deleteUser}
										submitEdition={formikProps.submitForm}
										cancelEdition={() => {
											formikProps.resetForm()
											setIsEditing(false)
										}}
									/>
								</td>
							</>
						)
					}
				</Formik>
			</TableRowStyles>
		</UsersTableRowContext.Provider>
	)
}


type OwnProps = Pick<UserRowProps, 'user' | 'displayMode' | 'activeGroupId' | 'loggedUser' | 'deleteUser'>

function mapDispatchToProps(dispatch: AppDispatch, ownProps: OwnProps) {

	const setIsLoading = (state: boolean) => dispatch(globalLoadingActions.setLoading(state))

	const updateUser = async (user: User) => await dispatch(userActions.update(user))
	const createUser = async (user: User) => await dispatch(userActions.create({
		...user,
		groupId: ownProps.activeGroupId,
	}))

	return ({
		saveChanges: async (user: User) => {
			setIsLoading(true)
			switch (ownProps.displayMode) {
				case "create":
					await createUser(user);
					break;
				case "edit":
					await updateUser(user);
					break;
			}
			setIsLoading(false)
		}
	})
}

export default connect(undefined, mapDispatchToProps)(UserTableRow_RAW)
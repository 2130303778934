import axios from "axios";
import useNotification from "Hooks/useNotification.hook";
import {useEffect} from "react";
import getToken from "Util/getToken";


export default function AxiosConfiguration() {

	const notification = useNotification();

	useEffect(() => {
		axios.defaults.baseURL = process.env.REACT_APP_API_URL;
		axios.interceptors.request.use(async config => {
			const token = await getToken()

			config.headers = {
				authorization: `Bearer ${token}`
			}

			return config
		})

		// Is this case, we only care about the errors, so we can display some notifications about it
		axios.interceptors.response.use(value => value, (error: any) => {
			notification.add("Falha ao processar requisição")

			// Maybe restore and improve this code later??

			// try {
			//
			// 	const {title, errors} = error.response.data as Partial<ValidationError>
			//
			// 	//	Validation error
			// 	if (title && errors) {
			// 		const stringifyErrors = Object
			// 			.entries(errors)
			// 			.reduce((previousValue, currentValue) => {
			//
			// 				const key = currentValue[0];
			// 				const value = currentValue[1][0];
			//
			// 				return previousValue + `\n${key}:${value}`
			// 			}, '\n')
			//
			// 		notification.add(title + stringifyErrors)
			// 	}
			//
			// 	// Request error
			// 	else if (error.response) {
			// 		notification.add(`${error.message}\n${error.response.config.url}`)
			// 	}
			//
			// 	// IDK, something generic
			// 	else {
			// 		notification.add(error.message)
			// 	}
			// }
			//
			// catch (e) {
			// 	console.error(e)
			// }

		})

		console.log("Axios default settings applied")
	}, [])

	return null
}

interface ValidationError {
	title: string
	status: number
	errors: Record<string, string[]>
}
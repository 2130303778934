import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit"
import axios from "axios"
import {User} from "Types"

interface CreateUserDto extends Pick<User, 'displayName' | 'email' | 'permission'> {
	groupId: string
}

type gId = {groupId: string}
type uId = {userId: string}

export const userAdapter = createEntityAdapter<User>({
	selectId: model => model.id,
	sortComparer: (a, b) => a.displayName.localeCompare(b.displayName)
})

export const userActions = {
	list: createAsyncThunk<User[], gId>('users/list', async (arg) => {
		return axios.get(`users/${arg.groupId}`).then(res => res.data)
	}),
	create: createAsyncThunk<User, CreateUserDto>('users/create', async (arg) => {
		return axios.post('users', arg).then(res => res.data)
	}),
	update: createAsyncThunk<void, Pick<User, 'id' | "permission" | 'isActive'>>('users/update', async (arg) => {
		const {id, ...userData} = arg
		return axios.put(`users/${id}`, userData).then(res => res.data)
	}),
	delete: createAsyncThunk<void, uId>('users/delete', async (arg) => {
		return axios.delete(`users/${arg.userId}`).then(res => res.data)
	}),
	getMe: createAsyncThunk<User, void>('users/get-me', async (arg) => {
		return axios.get('users/me').then(res => res.data)
	}),
}

export const usersSlice = createSlice({
	name: 'groups',
	initialState: userAdapter.getInitialState({
		me: <User | undefined> undefined
	}),
	reducers: {},
	extraReducers: builder => builder
		.addCase(userActions.list.fulfilled, (state, action) => {
			userAdapter.removeAll(state)
			userAdapter.addMany(state, action.payload)
		})
		.addCase(userActions.create.fulfilled, (state, action) => {
			userAdapter.addOne(state, action.payload)
		})
		.addCase(userActions.update.fulfilled, (state, action) => {
			const {id, ...userProps} = action.meta.arg
			userAdapter.updateOne(state, {
				id: id,
				changes: {...userProps}
			})
		})
		.addCase(userActions.delete.fulfilled, (state, action) => {
			userAdapter.removeOne(state, action.meta.arg.userId)
		})
		.addCase(userActions.getMe.fulfilled, (state, action) => {
			state.me = action.payload
		})
})
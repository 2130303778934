import styled from "styled-components"
import create from 'zustand'

import imageLoadingScreen from 'Assets/LogoWhite_SPRITE.png'
import loadingAnimation from 'Assets/loading.gif'

interface LoadingScreen {
	isActive: boolean
	toggleActive: (active?: boolean) => void
}

export const useLoadingScreen = create<LoadingScreen>(set => ({
	isActive: true,
	toggleActive(arg) {
		set(state => ({isActive: arg ?? !state.isActive}))
	}
}))

const Container = styled.div`
  position: fixed;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
	z-index: 1000; // Make it draw on top of everything.

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #0B4B8D;

  .logo { width: 600px; }

  .loading-animation {
    position: fixed;
    right: 30px;
    bottom: 30px;

    img {
	    display: block;
      width: 90px;
    }

    p {
      font-size: 1.25rem;
      text-align: center;

      font-family: "Segoe UI", serif;
      color: white;
    }
  }
	
	.placeholder {
		position: fixed;
		left: 0;
		top: 0;
		
		filter: alpha(50%);
		
		max-width: 100%;
		max-height: 100%;
	}
`

export default function LoadingScreen() {
	const loadingScreen = useLoadingScreen()

	if (!loadingScreen.isActive)
		return null

	return (
		<Container>
			<img className='logo' src={imageLoadingScreen} alt=""/>
			<div className='loading-animation'>
				<img src={loadingAnimation} alt=""/>
				<p>Loading</p>
			</div>
		</Container>
	)
}
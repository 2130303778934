import {createSelector} from "@reduxjs/toolkit"
import Enumerable from "linq"
import React, {createRef, useEffect} from "react"
import {useAppSelector} from "Store/hooks"
import {selectDownloads, selectGroups} from "Store/selectors"
import Chart from "chart.js/auto"
import Panel from "Components/Panel"
import {DownloadReportItem, Group} from "Types"
import {trelloColors} from "Util/colors"

export default function DownloadsChartPanel() {

	const canvasRef = createRef<HTMLCanvasElement>()
	const downloadsPerGroup = useAppSelector(selectDownloadsPerGroup)

	useEffect(() => {
		if (!canvasRef.current)
			return;

		const chart = new Chart(canvasRef.current, {
			type: 'bar',
			options: {
				skipNull: true,
				maintainAspectRatio: false,
				layout: {
					padding: 10
				},
				plugins: {
					legend: {
						display: false
					}
				},
				animation: false
			},
			data: {
				labels: downloadsPerGroup.map(d => d.name),
				datasets: [
					{
						data: downloadsPerGroup.map(d => d.count),
						backgroundColor: trelloColors
					}
				]
			}
		})

		return () => chart.destroy()
	}, [downloadsPerGroup])

	return (
		<Panel title="Downloads">
			<canvas ref={canvasRef}/>
		</Panel>
	)
}

const selectDownloadsPerGroup = createSelector(
	selectGroups,
	selectDownloads,
	(groups: Group[], downloads: DownloadReportItem[]) => {

		if (!groups || !downloads)
			return []

		const groupsEnumerable = Enumerable.from(groups)
		const downloadsEnumerable = Enumerable.from(downloads)

		const downloadsPerGroup = groupsEnumerable
			.groupJoin(downloadsEnumerable,
				g => g.id,
				d => d.groupId,
				(group, groupReportItems) => ({
					name: group.name,
					count: groupReportItems.count()
				}))
			.toArray()

		downloadsPerGroup.push(({
			name: 'Total',
			count: downloads.length
		}))

		return downloadsPerGroup
	}
)
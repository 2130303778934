export const trelloColors = [
	'#61bc4f',
	'#f0d500',
	'#fd9e1a',
	'#ea5a46',
	'#c276df',
	'#0078be',
]

export const trelloColorsNamed = {
	green: '#61bc4f',
	yellow: '#f0d500',
	orange: '#fd9e1a',
	red: '#ea5a46',
	purple: '#c276df',
	blue: '#0078be',
}
import styled from "styled-components"
import {CardBaseStyles} from "./CardBase"

const ExtendedCard = styled(CardBaseStyles)`
  position: relative;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  text-align: center;

  // width is being driven by the DashboardPage "card-list" container
  //width: 200px;
  height: 160px;

  .label {
    position: absolute;
    top: 0.5rem;
    font-size: 0.85rem;

    color: var(--card-label);
  }

  .content {
    font-size: 4rem;
    color: var(--card-content);
  }
`

interface SimpleCardProps {
	label: string | React.ReactNode
	content: any
	children?: any
}

export default function SimpleCard(props: SimpleCardProps){
	return (
		<ExtendedCard>
			<div className='label'> {props.label} </div>
			<div className='content'> {props.content} </div>
			{props.children}
		</ExtendedCard>
	)
}
import {IconSearch} from "Icons"
import styled from "styled-components"

interface Props {
	value: string
	onChange(newValue: string): void
}

const Container = styled.div`
  position: relative;

  width: 44ch;
  margin-bottom: 0.25em;

  input {
    width: 100%;
    font-size: 1rem;
	  
	  padding-left: 3ch;
  }

  .search-field--icon {
    display: block;
    position: absolute;

    top: 7px;
    left: 5px;

    width: 20px;
    height: 20px;

    pointer-events: none;

    * { fill: var(--blue1); }
  }
`

export default function UsersSearchField(props: Props) {
	return (
		<Container>
			<input type="search" onChange={e => props.onChange(e.target.value)} value={props.value} placeholder="Search..."/>
			<IconSearch className='search-field--icon'/>
		</Container>
	)
}
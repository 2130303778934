import RangedDatePicker from "Components/RangedDatePicker"
import {selectHospitals, selectTrainings, selectUniqueInstructors} from "Store/selectors"
import useReportFilter from "./useReportFilter"
import {useAppSelector} from "Store/hooks"

export default function ReportPageFilterControls() {

	const {setFilter, setPeriod, ...filters} = useReportFilter()

	return (
		<div className="report-filters">
			<label>
				<span> Period: </span>

				<RangedDatePicker
					startDate={filters.period.begin}
					endDate={filters.period.end}
					onChange={(begin, end) => setPeriod(begin.toISODate(), end.toISODate())}
				/>
			</label>

			<label>
				<span> Student </span>
				<input type="search" value={filters.studentSearch} onChange={e => setFilter("studentSearch", e.target.value)}/>
			</label>
		</div>
	)
}
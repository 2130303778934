import useLoadingProgress from "Hooks/useLoadingProgress";
import React, {useEffect} from "react";
import styled from "styled-components"

import HeaderBar from "./HeaderBar";
import SideBar from "./SideBar";
import {useLocation} from "react-router-dom";
import {Container} from "./DefaultPageTemplate.styles";

import iconWatermark from 'Assets/bd_watermark.png'

export interface ITemplateProps {
	title: string
	children: JSX.Element

	verticalExpand?: boolean
	ignoreNoOrgWarning?: boolean
}

export default function DefaultPageTemplate(props: ITemplateProps) {

	const location = useLocation();
	const loadingProgress = useLoadingProgress();

	useEffect(() => {
		document.title = props.title
	}, [location])

	return (
		<Container data-block-interaction={loadingProgress.isLoading}>
			<HeaderBar id="template-header"/>
			<SideBar id="template-sidebar"/>
			
			<div id="template-content" data-expand-vertical={props.verticalExpand}>
				<h1 className="content-header c-main"> {props.title} </h1>
				{props.children}
			</div>
		</Container>
	)
}
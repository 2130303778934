import Chart, {ScriptableContext} from "chart.js/auto"
import 'chartjs-adapter-luxon'
import Panel from "Components/Panel"
import Enumerable from "linq"
import {DateTime} from "luxon"
import {createRef, useEffect} from "react"
import {DashboardFilterPeriod} from "Store/dashboardFiltersSlice"
import {AppState, useAppSelector} from "Store/hooks"
import {selectDashboardSessions} from "Store/selectors"
import {Session} from "Types"

function getColor(ctx: ScriptableContext<'line'>, defaultColor: string) {
	if (ctx.parsed?.y == 0)
		return 'rgba(0,0,0,0)'

	return defaultColor
}

export default function TrainingsPerDayChartPanel() {

	const canvasRef = createRef<HTMLCanvasElement>()
	const trainingsPerDay = useAppSelector(selectTrainingsPerDay)

	useEffect(() => {
		if (!canvasRef.current)
			return
		
		const format = (isoDate: string) => DateTime
			.fromISO(isoDate)
			.toLocaleString({day: '2-digit', month: 'short'})

		const studentsChartData = trainingsPerDay.map(value => ({
			x: format(value.isoDate),
			y: value.studentsCount
		}))

		const instructorsChartData = trainingsPerDay.map(value => ({
			x: format(value.isoDate),
			y: value.instructorsCount
		}))

		const chart = new Chart(canvasRef.current, {
			type: 'line',
			options: {
				animation: false,
				maintainAspectRatio: false,
				layout: {
					padding: 10
				},
				datasets: {
					line: {
						borderWidth: 2,
						cubicInterpolationMode: 'monotone',
						tension: 0.4,
					}
				},
				scales: {
					y: {
						beginAtZero: true,
					}
				}
			},
			data: {
				datasets: [
					{
						label: 'Instructors',
						data: instructorsChartData,
						borderColor: ctx => getColor(ctx, '#0078be'),
						backgroundColor: ctx => getColor(ctx, '#0078be')
					},
					{
						label: 'Students',
						data: studentsChartData,
						borderColor: ctx => getColor(ctx, '#fd9e1a'),
						backgroundColor: ctx => getColor(ctx, '#fd9e1a')
					}
				]
			}
		})

		return () => chart.destroy()
	}, [canvasRef, trainingsPerDay])

	return (
		<Panel title="Training users">
			<canvas ref={canvasRef}/>
		</Panel>
	)
}

interface TrainingsPerDay {
	isoDate: string;
	studentsCount: number;
	instructorsCount: number
}

function selectTrainingsPerDay(state: AppState): TrainingsPerDay[] {
	const period = state.dashboardFilters.period
	const sessionReportList = selectDashboardSessions(state)

	const emptyTrainings = generateEmptyTrainings(period)
	const trainingsPerDay = getTrainingsPerDay(sessionReportList)

	return trainingsPerDay
		.concat(emptyTrainings
			.filter(training => !trainingsPerDay.some(real => real.isoDate == training.isoDate))
		)
		.sort(sorter)

	function sorter<T extends TrainingsPerDay>(a: T, b: T) {
		return DateTime.fromISO(a.isoDate) > DateTime.fromISO(b.isoDate) ? 1 : -1
	}
}

function getTrainingsPerDay(sessions: Session[]): TrainingsPerDay[] {
	return Enumerable
		.from(sessions)
		.select(el => ({
			date: DateTime.fromISO(el.openedAt),
			student: el.student,
			instructor: el.instructor
		}))
		.groupBy(
			el => el.date,
			el => ({
				students: el.student,
				instructor: el.instructor
			}),
			(date, people) => ({
				isoDate: date.toISODate(),
				studentsCount: people.select(p => p.students).distinct(s => s.name).count(),
				instructorsCount: people.select(p => p.instructor).distinct(s => s.id).count()
			} as TrainingsPerDay),
			date => date.toISODate()
		)
		.toArray()
}

function generateEmptyTrainings(period: DashboardFilterPeriod): TrainingsPerDay[] {
	const begin = DateTime.fromISO(period.begin)
	const days = DateTime
		.fromISO(period.end)
		.plus({day: 1})
		.diff(begin)
		.as("days")

	return Enumerable
		.range(0, days)
		.select((index) => ({
			isoDate: begin.plus({day: index}).toISODate(),
			studentsCount: 0,
			instructorsCount: 0
		} as TrainingsPerDay))
		.toArray()
}
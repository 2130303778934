import React, { ReactElement } from "react";
import styled from "styled-components";

export const CardBaseStyles = styled.div`
  //font-size: 1.5rem;
  //line-height: 1.25em;

  background-color: var(--card-background);

  border-radius: 5pt;
  //outline: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
`

export interface CardBaseProps {
	onClick?: () => void
	children?: ReactElement | any
}

export default function CardBase(props: CardBaseProps) {
	return (
		<CardBaseStyles onClick={props.onClick}>
			{props.children}
		</CardBaseStyles>
	)
}
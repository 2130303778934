import {BetterTableHeader} from "Components/BetterTable"
import useLoadingProgress from "Hooks/useLoadingProgress"
import {IconDownload} from "Icons"
import {DateTime} from "luxon"
import {ButtonFilterPopup} from "Pages/ReportsPage/ButtonFilterPopup"
import useReportFilter, {ReportFilterArg} from "Pages/ReportsPage/useReportFilter"
import React from "react"
import {useAppSelector} from "Store/hooks"
import {selectHospitals, selectSessionsFiltered, selectTrainings, selectUniqueInstructors} from "Store/selectors"
import DefaultPageTemplate from "Templates/Default";
import downloadBlobWithAuth from "Util/downloadBlobWithAuth"
import {BetterTableStyled} from "./BetterTableStyled"
import ReportPageFilterControls from "./ReportPageFilterControls"
import {Container} from "./styles";


interface SessionItemFormatted {
	instructor: string
	student: string
	hospital: string

	training: string
	grade: number

	startAt: string
	endAt: string

	sessionId: string
}

export default function ReportsPage() {

	return (
		<DefaultPageTemplate title="Reports" verticalExpand>
			<Container>

				<ReportPageFilterControls/>
				<DrawTableItems/>

			</Container>
		</DefaultPageTemplate>
	)
}

function DrawTableItems() {

	const filter = useReportFilter()
	const reportList = useAppSelector(state => selectSessionsFiltered(state, filter))

	const progress = useLoadingProgress()

	const onClickDownload = (sessionId: string) => {
		progress.start()

		downloadBlobWithAuth(`reports/${sessionId}/download`)
			.finally(() => progress.stop())
	}

	const mappedReportItems = reportList.map((session): SessionItemFormatted => ({
		instructor: session.instructor.name,
		student: session.student.name,
		hospital: session.student.hospitalName,
		training: session.training.name,

		// Format those later
		grade: session.grade,
		startAt: session.openedAt,
		endAt: session.closedAt,

		// Will be used for download button
		sessionId: session.id
	}))

	function drawDownloadIcon(sessionId: string) {
		return (
			<IconDownload
				title='Download this report'
				className='fill-blue1 pointer'
				onClick={() => onClickDownload(sessionId)}
			/>
		)
	}

	return (
		<BetterTableStyled data={mappedReportItems as any}>
			<BetterTableHeader for='instructor' title={<InstructorsTitle/>}/>
			<BetterTableHeader for='student'    title='Student'/>
			<BetterTableHeader for='hospital'   title={<HospitalTitle/>}/>
			<BetterTableHeader for='training'   title={<TrainingTitle/>}/>
			<BetterTableHeader for='grade'      title='Grade'/>
			<BetterTableHeader for='startAt'    title={'Start At'}  sorter={dateTimeSorter} draw={isoDate => dateDrawer(isoDate, 'date-time')}/>
			<BetterTableHeader for='endAt'      title={'End At'}    sorter={timeSorter}     draw={isoDate => dateDrawer(isoDate, 'time')}/>
			<BetterTableHeader for='sessionId'  title={''}          draw={drawDownloadIcon}/>
		</BetterTableStyled>
	)
}

function dateTimeSorter(isoDateA: string, isoDateB: string) {
	const dateA = DateTime.fromISO(isoDateA)
	const dateB = DateTime.fromISO(isoDateB)

	return dateA > dateB ? 1 : -1
}

function timeSorter(isoDateA: string, isoDateB: string){
	const dateA = DateTime.fromISO(isoDateA)
	const dateB = DateTime.fromISO(isoDateB)

	// Try sort by hour
	if (dateA.hour != dateB.hour) {
		return dateA.hour > dateB.hour ? 1 : -1
	} 

	// if both dates have the same hour, use the seconds to break the tie 
	else {
		return dateA.second > dateB.second ? 1 : -1
	}
}

function dateDrawer(isoDate: string, format: 'time' | 'date-time') {
	const dateTime = DateTime.fromISO(isoDate)

	switch (format) {
		case "time":
			return dateTime.toLocaleString({hour: "2-digit", minute: '2-digit'})
		case "date-time":
			return dateTime.toLocaleString({hour: "2-digit", minute: '2-digit', day: "2-digit", month: 'short'})
	}
}

function InstructorsTitle() {
	const map = useAppSelector(selectUniqueInstructors).map(s => ({key: s.id, value: s.name}))
	
	return (
		<CustomFilterBase title={'Instructor'} options={map} filterKey={'instructorId'}/>
	)
}

function TrainingTitle() {
	const map = useAppSelector(selectTrainings).map(s => ({key: s.id, value: s.name}))
	
	return (
		<CustomFilterBase title={'Training'} options={map} filterKey={'trainingId'}/>
	)
}

function HospitalTitle() {
	const map = useAppSelector(selectHospitals).map(s => ({key: s, value: s}))
	
	return (
		<CustomFilterBase title={'Hospital'} options={map} filterKey={'hospitalName'}/>
	)
}

function CustomFilterBase(props: {
	title: string,
	options: {key: string, value: any}[],
	filterKey: ReportFilterArg
}) {
	const filter = useReportFilter()
	const hasFilter = filter.hasFilter(props.filterKey)

	return (
		<>
			{props.title} 

			<ButtonFilterPopup
				className='filter-popup'
				data-has-filter={hasFilter}

				options={[{key: '', value: 'All'}, ...props.options]}
				activeOption={filter.getFilter(props.filterKey) ?? ''}
				onOptionSelected={(key) => filter.setFilter(props.filterKey, key)}
			/>
		</>
	)}
import styled from "styled-components"

export const Container = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  gap: 1em;
  align-items: center;
  align-content: center;
	z-index: 1;

  :not(:focus-within) .extra-user-info {
    display: none;
  }
	
	img {
		width: 30px;
	}
	
	.white-circle {
		width: 30px;
		height: 30px;
    border-radius: 100%;

    text-align: center;
    line-height: 1.9rem;
		
		color: black;
		outline: 1px solid var(--dark-blue);		
	}

  .extra-user-info {
    cursor: initial;
    position: absolute;
    top: calc(100% + 1em);
    right: 0;

    padding: 1em 2em;

    border-radius: 0.5em;
    //border: 1px solid black;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
    background-color: white;

    text-overflow-mode: none;

    white-space: nowrap;
  }
`
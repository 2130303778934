import useRoutesForLoggedUser from "Hooks/useRoutesForLoggedUser"
import React, {ReactElement} from "react";
import {Link, useLocation} from 'react-router-dom'
import styled from "styled-components";

const Styles = styled.nav`
  height: 100%;
  width: 4rem;
  background-color: var(--blue3);

  .sidebar-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    //border: 1px solid var(--main);
    height: 4em;
    text-decoration: none;

    svg {
      max-width: 2rem;
    }

    &[data-is-active="true"] * {
      fill: var(--orange0);
      color: var(--orange0);
    }

    &[data-is-active="false"] * {
      fill: var(--alt-white);
      color: var(--alt-white);
    }
  }
`

export default function SideBar(props: {id?:string}) {

	const routesForUser = useRoutesForLoggedUser()

	return (
		<Styles id={props.id}>

			{
				routesForUser.map(({path, Icon}) => (					
					<SidebarItem key={path} url={path} iconSvg={<Icon/>}/>
				))
			}

		</Styles>
	)
}

interface ISidebarItemProps {
	url?: string
	iconSvg?: ReactElement
}

function SidebarItem({iconSvg, url}: ISidebarItemProps) {

	const location = useLocation();
	const isActive = location.pathname === url;

	return (
		<Link to={url ?? '#'} className='sidebar-item' data-is-active={isActive}>
			{iconSvg}
		</Link>
	)
}
export interface Session {
	id: string
	grade: number
	
	openedAt: string
	closedAt: string

	student: Student
	training: Training
	instructor: Instructor
}

export interface Training {
	id: string
	name: string
}

export interface TrainingWithBundle extends Training {
	uploadData: string
	appVersion: string
	assetBundleHash: string
}

export interface Student {
	email: string
	name: string
	hospitalName: string
}

export interface Instructor {
	id: string
	email: string
	name: string
}

export interface User {
	id: string
	displayName: string
	email: string
	group: string
	permission: UserPermission,
	isActive: boolean
}

export enum UserPermission {
	None, Instructor, Manager, Admin, Support
}

export interface Group {
	id: string
	name: string
	thumbnailBase64: string
}

export interface Installer {
	commitId: string;
	version: string;
	uploadDate: string;
	isActive: boolean;
}

export interface DownloadReportItem {
	id: string
	version: string
	userId: string
	groupId: string
	timestamp: string
}